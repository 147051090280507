import { gql, useMutation } from '@apollo/client'
import { ACTIONS } from './useActions'

export const VISIT_ALL_ACTIONS = gql`
  mutation setVisitAllActions {
    setVisitAllActions
  }
`

export const useVisitAllActions = () =>
  useMutation(VISIT_ALL_ACTIONS, { refetchQueries: [ACTIONS] })
