import React, { FC, useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import {
  IndexPath,
  Select,
  SelectItem,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import {
  USE_ACTIVE_CHANNEL,
  useActiveChannel,
} from '../../../hooks/useActiveChannel'
import { snackbarStateVar, SnackbarStatus } from './Snackbar'
import { useUpdateChannel } from '../../../hooks/useUpdateChannel'
import { UpdateChannelVariables } from '../../../hooks/types/UpdateChannel'

// No change enum, because copy enum save in other apps
enum ChannelStates {
  TABLE_ORDERING = 'Table ordering',
  ORDER_AT_THE_BAR = 'Order at the bar',
  TABLE_SERVICE = 'Table service',
  WE_ARE_CLOSED = 'We are closed!',
}

export const states = [
  ChannelStates.TABLE_ORDERING,
  ChannelStates.ORDER_AT_THE_BAR,
  ChannelStates.TABLE_SERVICE,
  ChannelStates.WE_ARE_CLOSED,
]

const ApplicationState: FC = () => {
  const { data } = useActiveChannel()
  const [updateChannel] = useUpdateChannel()
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0)) // TODO real state
  const styles = useStyleSheet(themedStyles)
  const displayValue = states[selectedIndex.row]

  useEffect(() => {
    if (data?.customFields?.channelState) {
      const index = states.findIndex(
        (el) => el === data?.customFields?.channelState,
      )
      if (index === -1) {
        snackbarStateVar({
          open: true,
          status: SnackbarStatus.ERROR,
          message: 'Failed to load channel state',
        })
      } else {
        setSelectedIndex(new IndexPath(index))
      }
    }
  }, [data])

  const setSelectedHandler = async (value: IndexPath | IndexPath[]) => {
    if (Array.isArray(value)) return
    if (!data) return

    try {
      const { data: updateChannelData } = await updateChannel({
        variables: {
          input: {
            id: data.id,
            customFields: { channelState: states[value.row] },
          },
        },
        refetchQueries: [USE_ACTIVE_CHANNEL],
        optimisticResponse: (variables: UpdateChannelVariables) => ({
          updateChannel: {
            ...data,
            __typename: 'Channel',
            customFields: {
              __typename: 'ChannelCustomFields',
              channelState: variables.input?.customFields?.channelState!,
            },
          },
        }),
      })

      if (
        updateChannelData?.updateChannel.__typename ===
        'LanguageNotAvailableError'
      ) {
        throw new Error(updateChannelData.updateChannel.message)
      }
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.controlContainer}>
        <Select
          style={styles.select}
          status="control"
          size="medium"
          placeholder="State"
          selectedIndex={selectedIndex}
          onSelect={setSelectedHandler}
          value={(props) => (
            <Text {...props} style={[props?.style, styles.item]}>
              {displayValue}
            </Text>
          )}
        >
          {states.map((state) => (
            <SelectItem title={state} key={state} />
          ))}
        </Select>
      </View>
    </View>
  )
}

export default ApplicationState

const themedStyles = StyleService.create({
  select: {
    backgroundColor: 'blue',
    borderRadius: 4,
  },
  container: {
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
  },
  controlContainer: {
    padding: 24,
    borderRadius: 4,
    width: 250,
    maxWidth: '100%',
  },
  item: {
    marginRight: -8,
    flexGrow: 0,
    flexBasis: 'auto',
  },
})
