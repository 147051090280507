/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking'
import { LinkingOptions } from '@react-navigation/native'
import { RootStackParamList } from '../types'

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Dashboard: 'dashboard',
      Login: 'login',
      Products: 'products',
      Orders: 'orders',
      Tips: 'tips',
      Actions: 'actions',
      Settings: 'settings',
      Importing: 'importing',
      NotFound: '*',
    },
  },
}

export default linking
