import React from 'react'
import { View } from 'react-native'
import withLayout from '../../ui/layout/hoc/withLayout'
import ActionHeader from './ActionHeader'
import ActionsList from './ActionsList'

const ActionsScreen = () => {
  return (
    <View>
      <ActionHeader></ActionHeader>
      <ActionsList></ActionsList>
    </View>
  )
}

export default withLayout(ActionsScreen)
