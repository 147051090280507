import { Pressable, Text, View } from 'react-native'
import {
  Button,
  CalendarRange,
  Icon,
  RangeCalendar,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { predefinedRanges } from './datePoints'
import { TripleInput } from './TripleInput'

export interface DatePointValue {
  startDate: Date
  endDate: Date
}

interface DatePoint {
  label: string
  value: DatePointValue
}
const DatePickerView: FC<IDatePickerView> = ({
  onPush: onPushHandler,
  onCancel: onCancelHandler,
}) => {
  const styles = useStyleSheet(themedStyles)
  const [date, setDate] = useState(predefinedRanges[0].value)
  const [now] = useState(new Date())
  const firstCalendarRef = useRef(null)
  const secondCalendarRef = useRef(null)
  const [activePoint, setActivePoint] = useState('')

  const quarterAgo = useMemo(() => {
    if (now) {
      const newDate = new Date(now)
      newDate.setDate(now.getDate() - 90)

      return newDate
    } else {
      return null
    }
  }, [now])

  const pointOnClick = (point: DatePoint) => {
    const { value, label } = point
    setDate(value)
    setActivePoint(label)
    firstCalendarRef.current?.scrollToDate(value.startDate)
    secondCalendarRef.current?.scrollToDate(value.endDate)
  }

  const firstCalendarPress = (change: CalendarRange<Date>) => {
    setDate({ startDate: change.startDate })
  }

  const secondCalendarPress = (change: CalendarRange<Date>) => {
    setDate({ startDate: date.startDate, endDate: change.endDate })
  }

  const onPush = () => {
    onPushHandler(date)
  }

  const onCancel = () => {
    onCancelHandler()
  }

  const firstInputOnChange = (change) => {
    setDate({ startDate: change, endDate: date.startDate })
    firstCalendarRef.current?.scrollToDate(change)
  }

  const secondInputOnChange = (change) => {
    setDate({ startDate: date.startDate, endDate: change })
    secondCalendarRef.current?.scrollToDate(change)
  }

  if (!quarterAgo) return null

  return (
    <View style={styles.container}>
      <View style={styles.leftSide}>
        {predefinedRanges.map((elem) => (
          <Pressable
            style={[
              styles.point,
              activePoint === elem.label && styles.activePoint,
            ]}
            key={elem.label}
            onPress={() => pointOnClick(elem)}
          >
            <Text
              style={[
                styles.pointText,
                activePoint === elem.label && styles.activePointText,
              ]}
            >
              {elem.label}
            </Text>
          </Pressable>
        ))}
      </View>
      <View style={styles.rightSide}>
        <View style={styles.calendars}>
          <View style={styles.calendarContainer}>
            <Text style={styles.calendarText}>From:</Text>
            <RangeCalendar
              ref={firstCalendarRef}
              min={quarterAgo}
              max={now}
              style={styles.calendar}
              range={date}
              onSelect={firstCalendarPress}
            />
          </View>
          <View style={styles.calendarContainer}>
            <Text style={styles.calendarText}>To:</Text>
            <RangeCalendar
              ref={secondCalendarRef}
              boundingMonth
              min={quarterAgo}
              max={now}
              style={styles.calendar}
              range={date}
              onSelect={secondCalendarPress}
            />
          </View>
        </View>
        <View style={styles.rightBottom}>
          <View style={styles.bottomInputs}>
            <TripleInput
              minDate={quarterAgo}
              maxDate={now}
              data={date.startDate}
              onPushDate={firstInputOnChange}
            ></TripleInput>
            <Icon
              style={styles.icon}
              name="arrow-forward-outline"
              fill="#000000"
            />
            <TripleInput
              minDate={quarterAgo}
              maxDate={now}
              data={date.endDate}
              style={styles.rightInput}
              onPushDate={secondInputOnChange}
            ></TripleInput>
          </View>
          <View style={styles.buttons}>
            <Button
              style={styles.cancelButton}
              appearance={'ghost'}
              onPress={onCancel}
            >
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </Button>
            <Button style={styles.setButton} onPress={onPush}>
              <Text>Set Date</Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}

export default DatePickerView

const themedStyles = StyleService.create({
  container: {
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'background',
  },
  leftSide: {
    width: '15%',
    borderRight: '1px solid',
    borderColor: '#222B45',
    paddingVertical: 20,
  },
  point: {
    paddingLeft: 25,
    width: '100%',
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'open-sans-semibold',
    fontSize: 17,
  },
  activePoint: {
    backgroundColor: 'lightGrey',
  },
  pointText: {
    color: 'textColorPrimary',
  },
  activePointText: {
    color: '#886dd8',
  },
  rightSide: {
    width: '85%',
    paddingTop: 15,
    paddingBottom: 10,
  },
  rightBottom: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  calendars: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingRight: 15,
    borderBottom: '1px solid',
    borderColor: '#222B45',
    paddingBottom: 10,
  },
  calendar: {
    color: 'blue',
  },
  bottomInputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: 7,
    alignItems: 'center',
  },
  calendarContainer: {},
  calendarText: {
    marginBottom: 5,
    marginLeft: 23,
    fontFamily: 'open-sans-semibold',
    fontSize: 17,
  },
  leftInput: {},
  centerInput: {
    borderRadius: 0,
  },
  rightInput: {
    marginLeft: 21,
  },
  icon: {
    width: 20,
    height: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginRight: 20,
    paddingTop: 7,
  },
  cancelButton: {
    border: 'none',
    backgroundColor: '#f8f8fa',
    color: 'textColorPrimary',
    marginRight: 10,
    height: 10,
    padding: 4,
  },
  cancelButtonText: {
    color: 'textColorPrimary',
  },
  setButton: {
    border: 'none',
    backgroundColor: '#886dd8',
  },
})

interface IDatePickerView {
  onPush: (value: DatePointValue) => void
  onCancel: () => void
}
