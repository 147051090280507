import { gql, useLazyQuery } from '@apollo/client'

export const PAYMENT_DATA = gql`
  query getGatewayDataById($gatewayId: String) {
    getGatewayDataById(gatewayId: $gatewayId) {
      id
      status
      amount
      time
    }
  }
`
export const usePaymentData = () => useLazyQuery(PAYMENT_DATA)
