import React, { FC, useEffect, useState } from 'react'
import Profile from './Profile'
import { Icon, StyleService } from '@ui-kitten/components'
import NavItem from './NavItem'
import { RootStackParamList } from '../../../types'
import { Pressable, useWindowDimensions } from 'react-native'
import ApplicationState from '../../primitive/components/ApplicationState'
import useAdaptiveStyles from '../../../hooks/useAdaptiveStyles'
import LogOutNawItem from './LogOutNawItem'

const links: ILinks[] = [
  { link: 'Dashboard', name: 'Sales Dashboard' },
  { link: 'Orders', name: 'Orders' },
  { link: 'Tips', name: 'Tips' },
  { link: 'Actions', name: 'Actions' },
  { link: 'Products', name: 'Products' },
  { link: 'Settings', name: 'Settings' },
  { link: 'Importing', name: 'Importing' },
]

const Navbar: FC = React.memo(() => {
  const [open, setOpen] = useState(false)
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)
  const { width } = useWindowDimensions()
  const isSmallDevice = width < 900

  useEffect(() => {
    setOpen(!isSmallDevice)
  }, [isSmallDevice, setOpen])

  const modalToggle = () => {
    if (!isSmallDevice) return
    setOpen((prev) => !prev)
  }

  return (
    <>
      {open ? (
        <Pressable style={open && styles.mobileContainer} onPress={modalToggle}>
          <Pressable
            style={styles.container}
            onPress={(e) => {
              e.stopPropagation()
            }}
          >
            <Profile />
            <ApplicationState />
            {links.map(({ link, name }) => (
              <NavItem
                key={name}
                link={link}
                name={name}
                setOpen={modalToggle}
              />
            ))}
            <LogOutNawItem />
          </Pressable>
        </Pressable>
      ) : (
        <Pressable onPress={modalToggle} style={styles.menu}>
          <Icon name="menu-outline" style={styles.menuIcon} />
        </Pressable>
      )}
    </>
  )
})

export default Navbar

const themedStyles = StyleService.create({
  container: {
    cursor: 'auto',
    borderRadius: 7,
    maxWidth: 256,
    width: '100%',
    paddingBottom: 24,
    height: '100%',
    backgroundColor: 'viewBgColor',
  },
  mobileContainer: {},
  menu: {
    zIndex: 11,
    position: 'absolute',
    left: 8,
    top: 8,
  },
  menuIcon: {
    width: 24,
    height: 24,
  },
})

const themedSmStyles = StyleService.create({
  container: {
    maxWidth: 256,
    width: '100%',
    marginRight: 48,
    paddingBottom: 24,
    height: '100%',
    backgroundColor: 'viewBgColor',
  },
  mobileContainer: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    height: '100%',
    backgroundColor: '#0000007F',
  },
})

interface ILinks {
  link: keyof RootStackParamList
  name: string
}
