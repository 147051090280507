import { gql, useMutation } from '@apollo/client'
import { GET_ORDERS } from './useOrders'

const MODIFY_PL_ORDER = gql`
  mutation modifyPayLaterOrder($id: Int!, $changes: [Change]) {
    modifyPayLaterOrder(id: $id, changes: $changes) {
      id
    }
  }
`

export const useModifyPayLaterOrder = () =>
  useMutation(MODIFY_PL_ORDER, { refetchQueries: [GET_ORDERS] })
