import { gql, useQuery } from '@apollo/client'
import { SortOrder } from '../types/global-types'
import { REFUND_FRAGMENT } from './useRefundOrder'
import { getTime } from '../utils/getTime'
import { DatePointValue } from '../ui/primitive/components/DatePicker/DatePickerView'

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    total
    totalWithTax
    currencyCode
    totalQuantity
    createdAt
    state
    nextStates
    customer {
      firstName
      lastName
      orders {
        totalItems
      }
    }
    payments {
      id
      method
      amount
      refunds {
        ...RefundFragment
      }
    }
    lines {
      id
      quantity
      linePriceWithTax
      productVariant {
        id
        name
        currencyCode
        priceWithTax
      }
    }
    customFields {
      tableId
      seen
      waiterPayment
      waiterCalling
      customerName
      orderPaymentGateway
      acceptedOrder
      createTime
    }
    history(options: $options) {
      totalItems
      items {
        id
        data
      }
    }
  }
  ${REFUND_FRAGMENT}
`

export const GET_ORDERS = gql`
  query Orders($orderFilter: OrderListOptions) {
    orders(options: $orderFilter) {
      items {
        id
        total
        totalWithTax
        currencyCode
        state
        customFields {
          tableId
          customerName
          acceptedOrder
          createTime
        }
      }
    }
  }
`

const useOrders = (
  timeRange: DatePointValue | null = null,
  states: string[] = [],
) => {
  const date = getTime()

  let filter: IFilter

  if (!timeRange || timeRange.endDate === timeRange.startDate) {
    filter = {
      createdAt: {
        after: date,
      },
      state: { notEq: 'AddingItems' },
    }
  } else {
    filter = {
      createdAt: {
        after: timeRange.startDate,
        before: timeRange.endDate,
      },
      state: { notEq: 'AddingItems' },
    }
  }

  if (states.length) {
    filter.state.in = states
  }

  const data = useQuery(GET_ORDERS, {
    variables: {
      // options: {
      //   filter: {
      //     type: { eq: 'ORDER_STATE_TRANSITION' },
      //   },
      //   sort: { createdAt: SortOrder.DESC },
      // },
      orderFilter: {
        filter: filter,
        filterOperator: 'AND',
      },
    },
  })

  return {
    ...data,
    startPolling: data.startPolling,
    stopPolling: data.stopPolling,
    data: data.data?.orders.items || [],
  }
}

export default useOrders

interface IFilter {
  createdAt: {
    after: Date | string
    before?: Date
  }
  state: {
    notEq: string
    in?: string[]
  }
}
