import React, { useEffect, useReducer, useState } from 'react'
import { CurrencyCode } from '../../types/global-types'

export const OrderEditContext = React.createContext<IOrderEditContext>({
  activeOrder: null,
  setActiveOrder: () => {},
  editMode: false,
  setEditMode: () => {},
  changesList: [],
  newProductsList: [],
  clean: () => {},
  addChange: () => {},
  addNewProduct: () => {},
  addingProductMode: false,
  setAddProductMode: () => {},
  newProduct: null,
  setNewProduct: () => {},
})

export interface IOrderEditContext {
  activeOrder: any
  setActiveOrder: (order: any) => void
  editMode: boolean
  setEditMode: (state: boolean) => void
  addingProductMode: boolean
  setAddProductMode: (state: boolean) => void
  changesList: Array<IChange>
  newProductsList: Array<IChange>
  clean: () => void
  addChange: (change: IChange | IRemoveChange) => void
  addNewProduct: (change: IChange | IRemoveChange) => void
  newProduct: Partial<IChange> | null
  setNewProduct: (change: Partial<IChange> | null) => void
}

export interface IChange {
  quantity: number
  productId: number
  name: string
  price?: number
  currencyCode?: CurrencyCode
}

export interface IRemoveChange {
  productId: number
  type: 'Product' | 'Change'
}

function changeReducer(
  state: Array<IChange>,
  action: IChange | IRemoveChange | undefined,
): Array<IChange> {
  if (action) {
    const changeId = state.findIndex(
      ({ productId }) => productId === action.productId,
    )
    if (changeId !== -1) {
      if (action.hasOwnProperty('type')) {
        state.splice(changeId, 1)
        const res = Object.assign([], state)
        return res
      }

      const newState = state.map((item: IChange) => {
        if (item.productId === action.productId) {
          const sum = item.quantity + action.quantity
          if (sum) {
            return {
              name: action.name,
              productId: action.productId,
              quantity: item.quantity + action.quantity,
              price: action.price ?? 0,
              currencyCode: action.currencyCode ?? CurrencyCode.USD,
            }
          } else {
            return undefined
          }
        }
        return item
      })
      return newState.filter((item) => item)
    } else {
      if (action.hasOwnProperty('type')) {
        return state
      } else {
        return [...state, action]
      }
    }
  } else {
    return []
  }
}

export const OrderEditContextProvider = ({ children, order: inputOrder }) => {
  const [order, setOrder] = useState<any>(null)
  const [editMode, setEditMode] = useState(false)
  const [changes, changesDispatch] = useReducer(changeReducer, [])
  const [newProducts, productsDispatch] = useReducer(changeReducer, [])
  const [addingProductMode, setAddProductMode] = useState(false)
  const [newProduct, setNewProduct] = useState<Partial<IChange> | null>(null)

  useEffect(() => {
    setOrder(inputOrder)
  }, [inputOrder])

  const clean = () => {
    setAddProductMode(false)
    setEditMode(false)
    productsDispatch(undefined)
    changesDispatch(undefined)
  }

  const setActiveOrder = (newOrder: { id: string }) => {
    if (order && order.id === newOrder.id) {
      console.log('order is using')
    } else {
      setOrder(newOrder)
      clean()
    }
  }

  return (
    <OrderEditContext.Provider
      value={{
        activeOrder: order,
        setActiveOrder,
        editMode,
        setEditMode,
        addNewProduct: productsDispatch,
        newProductsList: newProducts,
        changesList: changes,
        clean,
        addChange: changesDispatch,
        addingProductMode,
        setAddProductMode,
        setNewProduct,
        newProduct,
      }}>
      {children}
    </OrderEditContext.Provider>
  )
}
