import { gql, useQuery } from '@apollo/client'
import { ActiveChannel } from './types/ActiveChannel'

export const CHANNEL_FRAGMENT = gql`
  fragment ChannelFragment on Channel {
    code
    id
    currencyCode
    customFields {
      channelState
      address
      channelState
      colour
      logo
      phone
    }
  }
`

export const USE_ACTIVE_CHANNEL = gql`
  query ActiveChannel {
    activeChannel {
      ...ChannelFragment
    }
  }
  ${CHANNEL_FRAGMENT}
`

export const useActiveChannel = () => {
  const data = useQuery<ActiveChannel>(USE_ACTIVE_CHANNEL)

  return { ...data, data: data.data?.activeChannel }
}
