import React, { FC } from 'react'
import { Pressable, Text, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const ModalActionButton: FC<IModalActionButton> = React.memo(
  ({ style, disabled, onPress, text }) => {
    const styles = useStyleSheet(themedStyles)

    return (
      <Pressable
        style={[styles.button, disabled && styles.disabled, style]}
        onPress={onPress}
        disabled={disabled}
      >
        <Text style={[styles.text, disabled && styles.disabledText]}>
          {text}
        </Text>
      </Pressable>
    )
  },
)

export default ModalActionButton

const themedStyles = StyleService.create({
  button: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: 'lightPink',
    borderWidth: 1,
    borderColor: 'pink',
    borderRadius: 4,
  },
  text: {
    textTransform: 'uppercase',
    color: 'pink',
    fontWeight: '700',
  },
  disabled: {
    borderColor: 'grey',
    backgroundColor: 'lightGrey',
  },
  disabledText: {
    color: 'grey',
  },
})

export interface IModalActionButton {
  style?: ViewStyle | ViewStyle[]
  onPress: () => void
  disabled?: boolean
  text: string
}
