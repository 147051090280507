import { Pressable, Text, View, ViewStyle } from 'react-native'
import React, { FC, useEffect, useState } from 'react'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import { dateAgoTransform } from '../../utils/dateAgoTransform'
import useOrder from '../../hooks/useOrder'

const ActionCard: FC<IActionCard> = ({
  time,
  openOrderModal,
  action,
  openActionModal,
}) => {
  const styles = useStyleSheet(themedStyles)
  const { order } = useOrder(action.orderId)
  const [old, setOld] = useState(true)
  const [status, setStatus] = useState('')
  const [icon, setIcon] = useState('')

  useEffect(() => {
    setOld(Date.now() - +action.timeStamp <= 300000)
    if (action.status === 'ArrangingPayment') {
      setStatus('Wants to pay')
      setIcon('credit-card')
    } else if (action.status === 'WaitingWaiters') {
      setStatus('Wants to pay/bill')
      setIcon('bell')
    } else if (action.status === 'PaymentSettled') {
      setStatus('Payed/wait accept')
      setIcon('credit-card')
    } else if (action.status === 'CallWaiter') {
      setStatus('Is calling')
      setIcon('bell')
    } else if (action.status === 'PayLater') {
      setStatus('New Order')
      setIcon('download')
    } else if (action.status === 'ArrangingAdditionalPayment') {
      setStatus('Arranging additional payment')
      setIcon('download')
    }
  }, [order, time])

  const getRenderStatus = () => {
    let status = action.status !== 'CallWaiter'
    if (status && order) {
      status =
        !action.visible ||
        order.state === 'Cancelled' ||
        (order.state === 'PaymentSettled' && order.customFields?.acceptedOrder)
    }
    return status
  }

  if (getRenderStatus()) {
    return null
  }

  const getCardColorStyle = (): ViewStyle => {
    if (action.status === 'CallWaiter' || action.status === 'WaitingWaiters') {
      return styles.itemCall
    }
    if (
      action.status === 'PayLater' ||
      action.status === '' ||
      action.status === 'PaymentSettled'
    ) {
      return old ? styles.itemOld : styles.itemNew
    }

    return styles.itemNew
  }

  return (
    <View style={[styles.item, getCardColorStyle()]}>
      {action.status === 'CallWaiter' ? (
        <Pressable onPress={() => openActionModal(action.id)}>
          <View style={styles.headerContainer}>
            <Text style={styles.titleText}>Table {action.tableId}</Text>
            {icon && <Icon name={icon} style={styles.icon} />}
          </View>
          <Text
            style={[
              styles.statusText,
              !old && styles.newOrderText,
              styles.callText,
              styles.callingStatusField,
            ]}>
            {status}
          </Text>
          <Text
            style={[
              styles.agoText,
              !old && styles.newOrderText,
              styles.callText,
            ]}>
            {dateAgoTransform(new Date(+action.timeStamp))}
          </Text>
        </Pressable>
      ) : (
        <Pressable onPress={() => openOrderModal(order.id)}>
          <View style={styles.headerContainer}>
            <Text style={styles.titleText}>
              Table {order.customFields.tableId}
            </Text>
            {icon && <Icon name={icon} style={styles.icon} />}
          </View>
          <Text
            style={[
              styles.statusText,
              !old && styles.newOrderText,
              action.status === 'WaitingWaiters' && styles.callText,
            ]}>
            {status}
          </Text>
          <Text
            style={[
              styles.statusText,
              !old && styles.newOrderText,
              action.status === 'WaitingWaiters' && styles.callText,
            ]}>
            {order.customFields.customerName}
          </Text>
          <Text
            style={[
              styles.agoText,
              !old && styles.newOrderText,
              action.status === 'WaitingWaiters' && styles.callText,
            ]}>
            {dateAgoTransform(new Date(+action.timeStamp))}
          </Text>
        </Pressable>
      )}
    </View>
  )
}

export default ActionCard

const themedStyles = StyleService.create({
  item: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginTop: 24,
    marginRight: 19,
    width: 189,
    height: 106,
    borderWidth: 1,
    borderColor: '#EDF1F7',
    borderStyle: 'solid',
  },
  callingStatusField: {
    marginBottom: 17,
  },
  titleText: {
    color: '#222B45',
    fontSize: 16,
    fontFamily: 'open-sans-semibold',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statusText: {
    fontFamily: 'open-sans-semibold',
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#8F9BB3',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  agoText: {
    marginTop: 18,
    fontFamily: 'open-sans-semibold',
    textTransform: 'uppercase',
    fontSize: 10,
    color: '#8F9BB3',
  },
  itemCall: {
    backgroundColor: '#C7E2FF',
  },
  filler: {
    height: 17,
    width: 1,
  },
  newOrderText: {
    color: '#FFFFFF',
  },
  itemOld: {
    color: '#EDF1F7',
  },
  callText: {
    color: '#8F9BB3',
  },
  itemNew: {
    backgroundColor: '#FF3D71',
  },
  icon: {
    width: 20,
    height: 20,
  },
})

export interface Action {
  orderId: number
  timeStamp: number
  status: string
  visible: boolean
  tableId: string
  id: string
}

interface IActionCard {
  time: string
  openOrderModal: (id: string) => void
  action: Action
  openActionModal: (id: number) => void
}
