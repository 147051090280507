import React, { FC } from 'react'
import { View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const Table: FC<ITable> = ({ children, style }) => {
  const styles = useStyleSheet(themedStyles)

  return <View style={[styles.container, style]}>{children}</View>
}

export default Table

const themedStyles = StyleService.create({
  container: {},
})

interface ITable {
  style?: ViewStyle
}
