import { gql, useQuery } from '@apollo/client'

const GET_TIPS = gql`
  query tips($dateRange: DateRange) {
    getTips(dateRange: $dateRange) {
      id
      value
      createdAt
      updatedAt
      orders {
        customFields {
          tableId
        }
        id
        subTotalWithTax
      }
    }
  }
`

const useTips = (timeRange) => {
  const data = useQuery(GET_TIPS, {
    variables: {
      dateRange: {
        start: timeRange.startDate,
        end: timeRange.endDate,
      },
    },
    pollInterval: 5000,
  })

  return { ...data, data: data.data?.getTips || [] }
}

export default useTips
