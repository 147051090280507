import React, { useState } from 'react'

export const TipTableContext = React.createContext<ITipProvider>({
  filterIndex: 0,
  setFilterIndex: () => {},
})

const TipProvider = ({ children }) => {
  const [filterIndex, setFilterIndex] = useState(0)

  return (
    <TipTableContext.Provider value={{ filterIndex, setFilterIndex }}>
      {children}
    </TipTableContext.Provider>
  )
}

export default TipProvider

interface ITipProvider {
  filterIndex: number
  setFilterIndex: (id: number) => void
}
