import React, { FC } from 'react'
import { Pressable, Text, View } from 'react-native'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import {
  Sorting,
  tableFields,
} from '../../../features/ordersScreen/OrdersTable'
import { tipTableFields } from '../../../features/tipScreen/TipTable'

const TableHeader: FC<ITableHeader> = ({
  list,
  setSortingDirection,
  setFilter,
}) => {
  const styles = useStyleSheet(themedStyles)

  const changeFilter = (
    name: (typeof tableFields)[number],
    active: boolean,
  ) => {
    if (active) {
      setSortingDirection((prev) =>
        prev === 'Ascending' ? 'Descending' : 'Ascending',
      )
    } else {
      setFilter(name)
      setSortingDirection('Ascending')
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.list}>
        {list.map(({ name, id, active, direction }, index) => (
          <Pressable
            key={id}
            style={[styles.item, !index && styles.first]}
            onPress={() => changeFilter(name, active)}
          >
            <Text style={styles.text}>{name}</Text>
            {active && (
              <View style={styles.iconContainer}>
                {direction === 'Ascending' ? (
                  <Icon name="arrow-ios-downward-outline" style={styles.icon} />
                ) : (
                  <Icon name="arrow-ios-upward-outline" style={styles.icon} />
                )}
              </View>
            )}
          </Pressable>
        ))}
        <View style={styles.empty} />
      </View>
    </View>
  )
}

export default TableHeader

interface ITableHeader {
  list: {
    id: number
    name: (typeof tableFields)[number] | (typeof tipTableFields)[number]
    active: boolean
    direction: Sorting
  }[]
  setFilter: (prev: string) => void
  setSortingDirection: React.Dispatch<React.SetStateAction<Sorting>>
}

const themedStyles = StyleService.create({
  container: {
    borderTopWidth: 4,
    borderTopColor: 'blue',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 2,
  },
  list: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  first: {
    minWidth: 144,
    paddingLeft: 24,
    width: '16%',
    flexBasic: '16%',
    flexGrow: 1,
    flexShrink: 1,
  },
  item: {
    width: '16%',
    minWidth: 120,
    paddingVertical: 18,
    flexDirection: 'row',
    flexBasic: '16%',
    flexGrow: 1,
    flexShrink: 1,
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
    color: 'textColorTableHeader',
  },
  empty: {
    minWidth: 48,
  },
  iconContainer: {
    marginLeft: 8,
  },
  icon: {
    width: 20,
    height: 20,
  },
})
