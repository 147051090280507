import {
  Icon,
  StyleService,
  Tooltip,
  useStyleSheet,
} from '@ui-kitten/components'
import {
  NativeSyntheticEvent,
  Pressable,
  StyleProp,
  TextInput,
  Text,
  TextInputSubmitEditingEventData,
  View,
  ViewStyle,
} from 'react-native'
import React, { FC, useEffect, useRef, useState } from 'react'
import { isValidDays, isValidMonth, isValidYear } from './helpers'

export const TripleInput: FC<ITripleInput> = ({
  onPushDate,
  data,
  maxDate,
  minDate,
  style: customStyle,
}) => {
  const styles = useStyleSheet(themedStyles)
  const [leftInput, setLeftInput] = useState('')
  const [centerInput, setCenterInput] = useState('')
  const [rightInput, setRightInput] = useState('')
  const [isError, setIsError] = useState('')
  const [errorVisible, setErrorVisible] = useState(false)

  const [leftFocused, setLeftFocused] = useState(false)
  const [centerFocused, setCenterFocused] = useState(false)
  const [rightFocused, setRightFocused] = useState(false)

  const leftInputRef = useRef(null)
  const centerInputRef = useRef(null)
  const rightInputRef = useRef(null)

  useEffect(() => {
    if (!leftFocused && !centerFocused && !rightFocused) {
      onPush()
    }
  }, [leftFocused, centerFocused, rightFocused])

  const focusToLeft = () => {
    leftInputRef?.current.focus()
  }

  const focusToCenter = () => {
    centerInputRef?.current.focus()
  }

  const focusToRight = () => {
    rightInputRef?.current.focus()
  }

  const leftOnChane = (text: string) => {
    if (text.length < leftInput.length) {
      setLeftInput(text)
    }

    if (isValidDays(text)) {
      setLeftInput(text)
      if (text.length === 2) {
        focusToCenter()
      }
    }
  }

  const centerOnChange = (text: string) => {
    if (text.length < leftInput.length) {
      setCenterInput(text)
      if (text.length === 0) {
        focusToLeft()
      }
    }

    if (isValidMonth(text)) {
      setCenterInput(text)
      if (text.length === 2) {
        focusToRight()
      }
    }
  }

  const rightOnChange = (text: string) => {
    if (text.length < leftInput.length) {
      setRightInput(text)
      if (text.length === 0) {
        focusToCenter()
      }
    }

    if (isValidYear(text)) {
      setRightInput(text)
    }
  }

  const onFocus = (id) => {
    switch (id) {
      case 1:
        setLeftFocused(true)
        break
      case 2:
        setCenterFocused(true)
        break
      case 3:
        setRightFocused(true)
        break
    }
  }

  const onBlur = (id) => {
    switch (id) {
      case 1:
        setLeftFocused(false)
        break
      case 2:
        setCenterFocused(false)
        break
      case 3:
        setRightFocused(false)
        break
    }
  }

  const onPush = (
    event:
      | NativeSyntheticEvent<TextInputSubmitEditingEventData>
      | undefined = undefined,
  ) => {
    if (event) {
      event.preventDefault()
    }

    if (centerInput && leftInput && rightInput) {
      const date = new Date(`${centerInput}-${leftInput}-${rightInput}`)

      if (date instanceof Date && isFinite(date)) {
        setLeftInput(date.getDate().toString())
        setCenterInput((date.getMonth() + 1).toString())
        setRightInput(date.getFullYear().toString())

        const dateTime = date.getTime()

        if (dateTime >= minDate.getTime() && dateTime <= maxDate.getTime()) {
          onPushDate(date)
        } else {
          setIsError('Min/max date error')
          focusToLeft()
        }
      } else {
        setIsError('Format error')
      }
    }
  }

  const isActive = leftFocused || centerFocused || rightFocused

  return (
    <View style={[styles.container, !!customStyle && customStyle]}>
      <View
        style={[
          styles.inputs,
          isActive && styles.activeInputs,
          !!isError && styles.errorInputs,
        ]}
      >
        <TextInput
          ref={leftInputRef}
          keyboardType="number-pad"
          value={leftInput}
          placeholder="dd"
          style={styles.input}
          onChangeText={leftOnChane}
          onSubmitEditing={onPush}
          onFocus={() => {
            onFocus(1)
          }}
          onBlur={() => {
            onBlur(1)
          }}
        />
        <TextInput
          ref={centerInputRef}
          keyboardType="number-pad"
          value={centerInput}
          placeholder="mm"
          style={styles.input}
          onChangeText={centerOnChange}
          onSubmitEditing={onPush}
          onFocus={() => {
            onFocus(2)
          }}
          onBlur={() => {
            onBlur(2)
          }}
        />
        <TextInput
          ref={rightInputRef}
          keyboardType="number-pad"
          value={rightInput}
          placeholder="yyyy"
          style={styles.input}
          onChangeText={rightOnChange}
          onSubmitEditing={onPush}
          onFocus={() => {
            onFocus(3)
          }}
          onBlur={() => {
            onBlur(3)
          }}
        />
      </View>
      <View style={styles.tooltipContainer}>
        {isError && (
          <Tooltip
            style={styles.tooltip}
            anchor={() => (
              <Pressable onPress={() => setErrorVisible(true)}>
                <Icon
                  name={'question-mark-circle-outline'}
                  fill={'#d32f2f'}
                  style={styles.icon}
                />
              </Pressable>
            )}
            visible={errorVisible}
            onBackdropPress={() => setErrorVisible(false)}
          >
            <Text style={styles.tooltipText}>{isError ?? 'Error'}</Text>
          </Tooltip>
        )}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    width: 175,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputs: {
    flexDirection: 'row',
    width: 150,
    border: '3px solid',
    borderRadius: 10,
    borderColor: 'viewBgColor',
    paddingHorizontal: 5,
    paddingLeft: 8,
    paddingVertical: 8,
  },
  activeInputs: {
    borderColor: '#8070af',
  },
  errorInputs: {
    borderColor: 'errorSnackBar',
  },
  input: {
    maxWidth: 45,
    margin: 0,
    outlineStyle: 'none',
    flexGrow: 2,
  },
  leftInput: {},
  rightInput: {
    maxWidth: 55,
    borderLeft: '1px solid',
    borderColor: 'textColorTableHeader',
  },
  iconContainer: {
    flexGrow: 1,
  },
  icon: {
    width: 21,
    height: 21,
  },
  tooltipContainer: {
    width: 21,
    height: 21,
  },
  tooltip: {
    backgroundColor: 'textColorTableHeader',
  },
  tooltipText: {
    color: '#111',
  },
})

interface ITripleInput {
  onPushDate: (date: Date) => void
  data: Date
  maxDate: Date
  minDate: Date
  style?: StyleProp<ViewStyle>
}
