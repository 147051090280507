import React from 'react'
import { Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
const TableHeaderStatic = ({ list }: { list: string[] }) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <View style={styles.list}>
        {list.map((name, index) => (
          <View key={index} style={[styles.item, !index && styles.first]}>
            <Text style={styles.text}>{name}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default TableHeaderStatic

const themedStyles = StyleService.create({
  container: {
    borderTopWidth: 4,
    borderTopColor: 'blue',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 2,
  },
  list: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  first: {
    paddingLeft: 24,
  },
  item: {
    paddingVertical: 18,
    flex: 1,
    maxWidth: 250,
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
    color: 'textColorTableHeader',
  },
  empty: {
    minWidth: 48,
  },
  iconContainer: {
    marginLeft: 8,
  },
  icon: {
    width: 20,
    height: 20,
  },
  dateHead: {
    flexDirection: 'row',
  },
})
