export interface ColorTheme {
  background: string
  viewBgColor: string
  textColorPrimary: string
  textColorSecondary: string
  textColorTableHeader: string
  textColorTableBody: string
  textColorBlue: string
  textColorWhite: string
  borderColorPrimary: string
  activeNavItem: string
  blue: string
  green: string
  pink: string
  orange: string
  grey: string
  lightGrey: string
  successSnackBar: string
  modalBackgroundColor: string
  errorSnackBar: string
  greyBackground: string
  modalLightGrey: string
  lightPink: string
  disabledColor: string
}

const LIGHT_THEME: ColorTheme = {
  background: '#FCFCFC',
  viewBgColor: '#FFFFFF',
  textColorPrimary: '#222B45',
  textColorSecondary: '#8F9BB3',
  textColorTableHeader: '#151A30',
  textColorTableBody: '#2E3A59',
  textColorBlue: '#3366FF',
  textColorWhite: '#FFFFFF',
  borderColorPrimary: '#EDF1F7',
  activeNavItem: '#3366FF14',
  blue: '#3366FF',
  green: '#00D68F',
  pink: '#FF3D71',
  orange: '#FFAA00',
  grey: '#8F9BB3',
  lightGrey: '#8F9BB314',
  successSnackBar: '#2e7d32',
  modalBackgroundColor: '#FFFFFF',
  errorSnackBar: '#d32f2f',
  greyBackground: '#F9F9F9',
  modalLightGrey: '#F7F9FC',
  lightPink: '#FFF2F2',
  disabledColor: '#8F9BB3',
}

const DARK_THEME: ColorTheme = {
  background: '#FCFCFC',
  viewBgColor: '#FFFFFF',
  textColorPrimary: '#222B45',
  textColorSecondary: '#8F9BB3',
  textColorTableHeader: '#151A30',
  textColorTableBody: '#2E3A59',
  textColorBlue: '#3366FF',
  textColorWhite: '#FFFFFF',
  borderColorPrimary: '#EDF1F7',
  activeNavItem: '#3366FF14',
  blue: '#3366FF',
  green: '#00D68F',
  pink: '#FF3D71',
  orange: '#FFAA00',
  grey: '#8F9BB3',
  lightGrey: '#8F9BB329',
  successSnackBar: '#2e7d32',
  modalBackgroundColor: '#FFFFFF',
  errorSnackBar: '#d32f2f',
  greyBackground: '#F9F9F9',
  modalLightGrey: '#F7F9FC',
  lightPink: '#FFF2F2',
  disabledColor: '#8F9BB3',
}

const Colors = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
}

export default Colors
