import React, { FC } from 'react'
import { View } from 'react-native'
import ProductList from './ProductList'
import OrderModalActions from './OrderModalActions'
import AddProductButton from './AddProductButton'
import OrderModalPricing from './OrderModalPricing'
import { OrderTransform } from '../../utils/orderTransform'
import useSeenOrder from '../../hooks/useSeenOrder'
import OrderModalHeader from './newOrderList/OrderModalHeader'
import EditModeButton from './EditModeButton'
import useModalContainerStyles from './hooks/useModalContainerStyles'
import { EditModeProvider } from './ModalContext'
import AddNewProduct from './AddNewProduct'
import { OrderEditContextProvider } from './OrderEditContext'

const OrderModal: FC<IOrderModal> = React.memo(({ order, closeHandler }) => {
  const { styles, containerStyles } = useModalContainerStyles()

  if (order.state === 'WaitingWaiters') {
    useSeenOrder(order)
  }

  // Tue, 06.12.2021 - 12:12  (11min ago) TODO time format

  return (
    <OrderEditContextProvider order={order}>
      <EditModeProvider order={order}>
        <View style={containerStyles}>
          <OrderModalHeader order={order} closeHandler={closeHandler} />
          <View style={styles.productList}>
            <ProductList products={order.lines} />
          </View>
          <View>
            <AddNewProduct />
          </View>
          <View style={styles.addProduct}>
            <AddProductButton />
            <EditModeButton order={order} />
          </View>
          <View style={styles.modalBottom}>
            <OrderModalActions order={order} />
            <OrderModalPricing order={order} />
          </View>
        </View>
      </EditModeProvider>
    </OrderEditContextProvider>
  )
})

export default OrderModal

interface IOrderModal {
  order: OrderTransform
  closeHandler: () => void
}
