import React, { FC, useEffect, useMemo, useState } from 'react'
import { Orders_orders_items_lines } from '../../hooks/types/Orders'
import { OrderTransform } from '../../utils/orderTransform'
import { Status } from '../../ui/primitive/components/OrderStatus'
import isEqual from 'lodash/isEqual'
import { CurrencyCode } from '../../types/global-types'

export const EditModeContext = React.createContext<IEditModeContext>({
  state: {
    lines: [],
    disable: true,
    equal: true,
    newProductMode: false,
    newProduct: null,
    newProducts: [],
  },
  setState: () => {},
})

interface IEditModeContext {
  state: IState
  setState: React.Dispatch<React.SetStateAction<IState>>
}

interface IState {
  lines: Orders_orders_items_lines[]
  disable: boolean
  equal: boolean
  newProductMode: boolean
  newProduct?: NewProduct | null
  newProducts: NewProduct[]
}

export interface NewProduct {
  id: string
  quantity: number
  name: string
  priceWithTax: number
  currencyCode: CurrencyCode
}

interface IEditModeProvider {
  order: OrderTransform
}

export const EditModeProvider: FC<IEditModeProvider> = ({
  children,
  order,
}) => {
  const [state, setState] = useState<IState>({
    lines: order.lines,
    disable: order.state !== Status.CUSTOM_MODIFYING,
    equal: true,
    newProductMode: false,
    newProducts: [],
  })

  useEffect(() => {
    setState({
      lines: order.lines,
      disable: order.state !== Status.CUSTOM_MODIFYING,
      equal: true,
      newProductMode: false,
      newProducts: [],
    })
  }, [setState, order])

  useEffect(() => {
    const equal = isEqual(order.lines, state.lines) && !state.newProducts.length

    if (state.equal !== equal) {
      setState((prev) => ({ ...prev, equal: equal }))
    }
  }, [state, setState, order.lines])

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state, setState],
  )

  return (
    <EditModeContext.Provider value={value}>
      {children}
    </EditModeContext.Provider>
  )
}
