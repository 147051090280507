import React, { FC, useState } from 'react'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import Table from '../../ui/layout/table/Table'
import { ScrollView, useWindowDimensions, View } from 'react-native'
import useTips from '../../hooks/useTips'
import TableHeaderStatic from '../../ui/layout/table/TableHeaderStatic'
import TableBodyStatic from '../../ui/layout/table/TableBodyStatic'
import DatePicker from '../../ui/primitive/components/DatePicker'
import { formatDateRange } from '../../ui/primitive/components/DatePicker/helpers'

export const tipTableFields = [
  'Table',
  'Tip sum',
  'Orders',
  'Orders total',
  'Total + Tip',
  'Date',
]

const TipTable: FC = React.memo(() => {
  const desktopStyles = useStyleSheet(themedStyles)
  const mobileStyles = useStyleSheet(themedSmStyles)
  const [timeRange, setTimeRange] = useState(
    formatDateRange({ startDate: new Date(), endDate: new Date() }),
  )
  const { data } = useTips(timeRange)
  const { width } = useWindowDimensions()

  const isSmallDevice = width < 900
  const styles = isSmallDevice
    ? { ...desktopStyles, ...mobileStyles }
    : desktopStyles

  return (
    <View style={styles.container}>
      <View style={[styles.datePickerContainer]}>
        <DatePicker
          visibleDate={timeRange}
          onCancel={() => {
            formatDateRange({ startDate: new Date(), endDate: new Date() })
          }}
          onPush={setTimeRange}
        />
      </View>
      <ScrollView horizontal contentContainerStyle={styles.contentStyle}>
        <Table style={styles.table}>
          <TableHeaderStatic list={tipTableFields} />
          <ScrollView>
            <TableBodyStatic list={data} />
          </ScrollView>
        </Table>
      </ScrollView>
    </View>
  )
})

export default TipTable

export type Sorting = 'Ascending' | 'Descending'

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'viewBgColor',
    flex: 1,
    marginRight: 87,
  },
  datePickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 10,
  },
  datePicker: {
    minWidth: 324,
    marginBottom: 15,
  },
  dropdown: {
    fontSize: 10,
    backgroundColor: '#EDF1F7',
  },
  dropdownText: {
    fontSize: 10,
    color: 'red',
  },
  contentStyle: {
    flex: 1,
    minWidth: 800,
  },
  table: {
    flex: 1,
  },
})

const themedSmStyles = StyleService.create({
  container: {
    backgroundColor: 'viewBgColor',
    flex: 1,
  },
})
