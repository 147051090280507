import React, { FC, useContext, useEffect, useState } from 'react'
import useOrders from '../../hooks/useOrders'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import Table from '../../ui/layout/table/Table'
import TableHeader from '../../ui/layout/table/TableHeader'
import TableBody from '../../ui/layout/table/TableBody'
import { ScrollView, useWindowDimensions, View } from 'react-native'
import { orderTransform } from '../../utils/orderTransform'
import { OrderEditContextProvider } from './OrderEditContext'
import { OrdersContext } from './OrdersTableContext'
import { Status } from '../../ui/primitive/components/OrderStatus'
import StateSelectDroplist from './StateSelectDroplist'

export const tableFields = [
  'Table',
  'Order ID',
  'Customer',
  'Time',
  'Amount',
  'Status',
] as const

const OrdersTable: FC = React.memo(() => {
  const desktopStyles = useStyleSheet(themedStyles)
  const { width } = useWindowDimensions()
  const { dateRange } = useContext(OrdersContext)
  const [states, setStates] = useState([])
  const [filter, setFilter] = useState<(typeof tableFields)[number]>('Time')
  const [firstVisit, setFirstVisit] = useState(true)
  const [sortingDirection, setSortingDirection] = useState<Sorting>('Ascending')
  const mobileStyles = useStyleSheet(themedSmStyles)
  // const [comSound, setSound] = useState()
  const isSmallDevice = width < 900
  const styles = isSmallDevice
    ? { ...desktopStyles, ...mobileStyles }
    : desktopStyles
  const { data, loading, startPolling, stopPolling, refetch } = useOrders(
    dateRange,
    states,
  )
  // const buttonRef = useRef(null)

  useEffect(() => {
    if (startPolling) startPolling(2000)
  }, [startPolling])

  // useEffect(() => {
  //   if (!firstVisit && data.length > 0) {
  //     buttonRef.current.onPressIn()
  //   }
  // }, [data])

  useEffect(() => {
    refetch()
  }, [dateRange, states])

  useEffect(() => {
    if (!loading) {
      visitHandler()
    }
  }, [loading])

  const visitHandler = () => {
    if (firstVisit) {
      setTimeout(() => {
        setFirstVisit(false)
      }, 2000)
    }
  }

  // async function playSound() {
  //   const { sound } = await Audio.Sound.createAsync(
  //     require('../../assets/audio/notif.wav'),
  //   )
  //   setSound(sound)
  //
  //   return sound.playAsync()
  // }

  // useEffect(() => {
  //   return comSound
  //     ? () => {
  //         comSound.unloadAsync()
  //       }
  //     : undefined
  // }, [comSound])

  const list = orderTransform(data, filter, sortingDirection)
  const headerList = tableFields.map((name, i) => ({
    id: i,
    name,
    active: name === filter,
    direction: sortingDirection,
  }))

  return (
    <>
      <StateSelectDroplist setItems={setStates} />
      <View style={styles.container}>
        <ScrollView horizontal contentContainerStyle={styles.contentStyle}>
          {/*<Button*/}
          {/*  style={{ display: 'none' }}*/}
          {/*  ref={buttonRef}*/}
          {/*  onPressIn={playSound}>*/}
          {/*  test*/}
          {/*</Button>*/}
          <Table style={styles.table}>
            <TableHeader
              list={headerList}
              setSortingDirection={setSortingDirection}
              setFilter={setFilter}
            />
            <OrderEditContextProvider>
              <ScrollView>
                <TableBody
                  endPoll={stopPolling}
                  startPoll={startPolling}
                  list={list}
                />
              </ScrollView>
            </OrderEditContextProvider>
          </Table>
        </ScrollView>
      </View>
    </>
  )
})

export default OrdersTable

export type Sorting = 'Ascending' | 'Descending'

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'viewBgColor',
    flex: 1,
    marginRight: 87,
  },
  contentStyle: {
    flex: 1,
    minWidth: 800,
  },
  table: {
    flex: 1,
  },
})

const themedSmStyles = StyleService.create({
  container: {
    backgroundColor: 'viewBgColor',
    flex: 1,
  },
})
