import React, { useContext, useMemo } from 'react'
import { View, Text } from 'react-native'
import { useDashboardTableData } from '../../hooks/useDashboardTableData'
import { DashboardContext } from './DashboardContext'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import DashboardTableRow from './DashboardTableRow'

const tableColumns = ['Group / Type', 'Amount', 'Total in CHF']

const DashboardTable = () => {
  const styles = useStyleSheet(themedStyles)
  const { dateRange } = useContext(DashboardContext)
  const tableData = useDashboardTableData(dateRange)

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Sales by product group</Text>
        <Text style={styles.subTitle}>Ranking by sales total</Text>
      </View>
      <View>
        <View style={styles.tableHead}>
          {tableColumns.map((title) => (
            <View key={title} style={styles.headCell}>
              <Text style={styles.headCellText}>{title}</Text>
            </View>
          ))}
        </View>
        {tableData.map((line, id) => (
          <DashboardTableRow
            total={line.total}
            name={line.name}
            amount={line.amount}
            id={id}
            key={id}
          />
        ))}
      </View>
    </View>
  )
}

export default DashboardTable

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    flexDirection: 'column',
    marginBottom: 6,
  },
  title: {
    fontSize: 22,
    fontFamily: 'open-sans-bold',
  },
  subTitle: {
    fontSize: 15,
    fontFamily: 'open-sans-regular',
  },
  headCellText: {
    fontFamily: 'open-sans-bold',
    fontSize: 15,
  },
  tableHead: {
    width: 375,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'modalLightGrey',
  },
  headCell: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 53,
  },
})
