import { Image, StyleSheet, Text, View } from 'react-native'
import React, { FC } from 'react'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { useActiveChannel } from '../../../hooks/useActiveChannel'
import { useActiveAdministrator } from '../../../hooks/useActiveAdministrator'

const Profile: FC = React.memo(() => {
  const styles = useStyleSheet(themedStyles)
  const { data } = useActiveChannel()
  const { data: me } = useActiveAdministrator()

  return (
    <View style={styles.container}>
      <Image
        style={imageStyles.avatar}
        source={{
          uri: 'https://snack-web-player.s3.us-west-1.amazonaws.com/v2/43/static/media/react-native-logo.79778b9e.png',
        }}
      />
      <View style={styles.info}>
        <Text style={styles.text}>{data?.code}</Text>
        <Text style={styles.subText}>
          {me?.firstName} {me?.lastName}
        </Text>
      </View>
    </View>
  )
})

export default Profile

const imageStyles = StyleSheet.create({
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 24,
  },
})

const themedStyles = StyleService.create({
  container: {
    padding: 24,
    flexDirection: 'row',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
  },
  info: {
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    fontWeight: '700',
    fontSize: 18,
    color: 'textColorPrimary',
    width: '100%',
    flexGrow: 1,
    flex: 1,
  },
  subText: {
    fontSize: 12,
    fontWeight: '400',
    color: 'textColorSecondary',
  },
})
