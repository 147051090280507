import React, { FC } from 'react'
import { useUnseenMessageCount } from '../../../hooks/useUnseenMessageCount'
import { Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const NewOrderCount: FC = React.memo(() => {
  const unseenMessageCount = useUnseenMessageCount()
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{unseenMessageCount}</Text>
    </View>
  )
})

export default NewOrderCount

const themedStyles = StyleService.create({
  container: {
    width: 30,
    height: 28,
    backgroundColor: 'blue',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'textColorWhite',
    fontSize: 12,
  },
})
