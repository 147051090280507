import { gql, useQuery } from '@apollo/client'
import { REFUND_FRAGMENT } from './useRefundOrder'
import { SortOrder } from '../types/global-types'

export const ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      total
      totalWithTax
      currencyCode
      totalQuantity
      createdAt
      state
      nextStates
      customer {
        firstName
        lastName
        orders {
          totalItems
        }
      }
      payments {
        id
        method
        amount
        refunds {
          ...RefundFragment
        }
      }
      lines {
        id
        quantity
        linePriceWithTax
        productVariant {
          id
          name
          currencyCode
          priceWithTax
        }
      }
      customFields {
        tableId
        seen
        waiterPayment
        waiterCalling
        customerName
        orderPaymentGateway
        acceptedOrder
        createTime
      }
      history {
        totalItems
        items {
          id
          data
        }
      }
    }
  }
  ${REFUND_FRAGMENT}
`

const useOrder = (id: number) => {
  const data = useQuery(ORDER, {
    variables: {
      id,
      options: {
        filter: {
          type: { eq: 'ORDER_STATE_TRANSITION' },
        },
        sort: { createdAt: SortOrder.DESC },
      },
    },
  })

  return { ...data, order: data.data?.order, refetch: data.refetch }
}

export default useOrder
