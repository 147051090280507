import React, { FC, useContext } from 'react'
import { Pressable, Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { OrderTransform } from '../../utils/orderTransform'
import { OrderEditContext } from './OrderEditContext'
import { useModifyPayLaterOrder } from '../../hooks/useModifyPayLaterOrder'

const EditModeButton: FC<IEditModeButton> = () => {
  const {
    activeOrder,
    editMode,
    setEditMode,
    newProductsList,
    changesList,
    clean,
  } = useContext(OrderEditContext)
  const styles = useStyleSheet(themedStyles)
  const [modifyMutation] = useModifyPayLaterOrder()

  const newEditModeHandler = async () => {
    if (!editMode) {
      setEditMode(true)
    } else {
      setEditMode(false)
      clean()
    }
  }

  const edited = !!newProductsList.length || !!changesList.length

  const saveHandler = async () => {
    try {
      await modifyMutation({
        variables: {
          id: +activeOrder.id,
          changes: [...newProductsList, ...changesList],
        },
      })
      clean()
    } catch (e) {
      console.log(e)
    }
  }

  if (!activeOrder || activeOrder.state !== 'PayLater') return null

  return (
    <View style={styles.buttons}>
      <Pressable
        style={[styles.container, editMode && styles.editMode]}
        onPress={newEditModeHandler}
      >
        <Text style={[styles.text, editMode && styles.editModeText]}>
          {!editMode ? 'Edit' : 'Cancel'}
        </Text>
      </Pressable>
      {edited && (
        <Pressable
          style={[styles.container, editMode && styles.editMode]}
          onPress={saveHandler}
        >
          <Text style={[styles.text, styles.editModeText]}>Save</Text>
        </Pressable>
      )}
    </View>
  )
}

export default EditModeButton

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: 'lightGrey',
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 4,
    marginRight: 16,
    marginLeft: 16,
  },
  buttons: {
    flexDirection: 'row',
    paddingHorizontal: 4,
  },
  text: {
    color: 'textColorSecondary',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  editMode: {
    backgroundColor: 'lightPink',
    borderColor: 'pink',
  },
  editModeText: {
    color: 'pink',
  },
})

interface IEditModeButton {
  order: OrderTransform
}
