import { getTime } from '../utils/getTime'
import { gql, useQuery } from '@apollo/client'

const OPEN_ORDERS = gql`
  query getOpenOrders($tableId: String!) {
    getOpenOrders(tableId: $tableId) {
      id
      total
      totalWithTax
      customFields {
        tableId
        createTime
        customerName
      }
    }
  }
`

const useOpenOrdersByTable = (tableId: string) => {
  const data = useQuery(OPEN_ORDERS, {
    variables: {
      tableId,
    },
  })

  return data.data?.getOpenOrders || []
}

export default useOpenOrdersByTable
