import { gql, useMutation } from '@apollo/client'
import { REFUND_FRAGMENT } from './useRefundOrder'
import { RefundSettled, RefundSettledVariables } from './types/RefundSettled'

export const REFUND_SETTLED = gql`
  mutation RefundSettled($input: SettleRefundInput!) {
    settleRefund(input: $input) {
      ...RefundFragment
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${REFUND_FRAGMENT}
`

export const useRefundSettled = () =>
  useMutation<RefundSettled, RefundSettledVariables>(REFUND_SETTLED)
