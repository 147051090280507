import React, { FC, useCallback, useContext } from 'react'
import { CurrencyCode } from '../../../types/global-types'
import { EditModeContext } from '../ModalContext'
import ListItem from './ListItem'
import { OrderEditContext } from '../OrderEditContext'

interface IListItemContainer {
  id: string
  linePriceWithTax: number
  quantity: number
  name: string
  currencyCode: CurrencyCode
  priceWithTax: number
  firstItem: boolean
}

const ListItemContainer: FC<IListItemContainer> = React.memo(
  ({
    id,
    firstItem,
    currencyCode,
    priceWithTax,
    name,
    quantity,
    linePriceWithTax,
  }) => {
    const { setState } = useContext(EditModeContext)
    const { addChange } = useContext(OrderEditContext)

    const changeQuantityHandler = useCallback(
      (count: -1 | 1) => () => {
        // setState((prev) => ({
        //   ...prev,
        //   lines: prev.lines.map((el) =>
        //     el.id === id ? { ...el, quantity: el.quantity + count } : el,
        //   ),
        // }))
        addChange({ quantity: count, name, productId: +id })
      },
      [id, setState],
    )

    const deleteHandler = useCallback(() => {
      addChange({ type: 'Change', productId: +id })
    }, [id, addChange])

    return (
      <ListItem
        linePriceWithTax={linePriceWithTax}
        quantity={quantity}
        name={name}
        currencyCode={currencyCode}
        priceWithTax={priceWithTax}
        firstItem={firstItem}
        changeQuantityHandler={changeQuantityHandler}
        deleteHandler={deleteHandler}
      />
    )
  },
)

export default ListItemContainer
