import {
  Button as DefaultButton,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import React from 'react'
import { StyleSheet } from 'react-native'

const themedStyles = StyleService.create({
  container: {
    borderRadius: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 18,
    fontWeight: '500',
    minHeight: 56,
  },
})

export const buttonStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    // color: Colors.light.secondaryText,
    fontSize: 18,
  },
})

export const disabledStyles = StyleSheet.create({
  primary: {},
  secondary: {},
  blue: {},
})

export type ButtonProps = DefaultButton['props'] & {
  type: 'primary' | 'secondary' | 'blue'
}

const Button: React.FC<ButtonProps> = ({
  style,
  children,
  type,
  disabled,
  ...otherProps
}) => {
  const styles = useStyleSheet(themedStyles)
  // const typeStyles =
  //   type === 'primary'
  //     ? buttonStyles.primary
  //     : type === 'secondary'
  //     ? buttonStyles.secondary
  //     : buttonStyles.blue

  const disabledStyle =
    disabled &&
    (type === 'primary'
      ? disabledStyles.primary
      : type === 'secondary'
      ? disabledStyles.secondary
      : disabledStyles.blue)

  return (
    <DefaultButton
      {...otherProps}
      disabled={disabled}
      style={[styles.container, style, disabledStyle]}
    >
      <>{children}</>
    </DefaultButton>
  )
}

export default Button
