import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { useWindowDimensions } from 'react-native'

const useModalContainerStyles = () => {
  const { width, height } = useWindowDimensions()
  const desktopStyles = useStyleSheet(themedStyles)
  const mobileStyles = useStyleSheet(themedSmStyles)

  const isSmallDevice = width < 900
  const styles = isSmallDevice
    ? { ...desktopStyles, ...mobileStyles }
    : desktopStyles

  const containerStyles =
    width < 500
      ? { ...styles.container, width, height }
      : width < 900
      ? desktopStyles.tablet
      : styles.container

  return { styles, containerStyles }
}

export default useModalContainerStyles

const themedStyles = StyleService.create({
  container: {
    minWidth: 878,
    width: 878,
    paddingHorizontal: 32,
    paddingVertical: 32,
  },
  tablet: {
    minWidth: 470,
    width: 470,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  closeIcon: {
    position: 'absolute',
    right: 4,
    top: -8,
  },
  productList: {
    marginTop: 28,
  },
  addProduct: {
    flexDirection: 'row-reverse',
    marginBottom: 16,
  },
  modalBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
})

const themedSmStyles = StyleService.create({
  container: {
    minWidth: '100%',
    paddingVertical: 48,
    paddingHorizontal: 16,
  },
  closeIcon: {
    position: 'absolute',
    top: 4,
    right: -4,
  },
  productList: {
    marginTop: 20,
  },
  addProduct: {
    flexDirection: 'row',
  },
  modalBottom: {
    marginTop: 16,
    flexDirection: 'column-reverse',
  },
})
