import React from 'react'
import { View, Text } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const DashboardCard = ({ title, content, subContent = '' }) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <View style={styles.topLine}></View>
      <Text style={[styles.text, styles.title]}>{title}</Text>
      <Text style={[styles.text, styles.content]}>{content}</Text>
      {subContent && (
        <Text style={[styles.text, styles.subContent]}>{subContent}</Text>
      )}
    </View>
  )
}

export default DashboardCard

const themedStyles = StyleService.create({
  container: {
    width: 241,
    height: 126,
    border: '1px solid #C5CEE0',
    borderRadius: 4,
    marginBottom: 21,
    marginRight: 24,
  },
  topLine: {
    height: 3,
    width: '100%',
    backgroundColor: '#3366FF',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  text: {
    marginLeft: 24,
  },
  title: {
    marginTop: 18,
    fontSize: 18,
    fontFamily: 'open-sans-bold',
  },
  content: {
    fontSize: 32,
    fontFamily: 'open-sans-bold',
  },
  subContent: {
    fontSize: 12,
    fontFamily: 'open-sans-regular',
  },
})
