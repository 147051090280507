import { DocumentNode, gql, MutationResult, useMutation } from '@apollo/client'
import {
  TransitionOrderToState,
  TransitionOrderToState_transitionOrderToState_Order,
  TransitionOrderToStateVariables,
} from './types/TransitionOrderToState'
import { Status } from '../ui/primitive/components/OrderStatus'
import { OrderTransform } from '../utils/orderTransform'
import { useCallback, useMemo } from 'react'

export const TRANSITION_ORDER_TO_STATE = gql`
  mutation TransitionOrderToState($state: String!, $id: ID!) {
    transitionOrderToState(state: $state, id: $id) {
      ... on Order {
        id
        state
        nextStates
      }

      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
      }

      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`

const useTransitionOrderToState = () => {
  return useMutation<TransitionOrderToState, TransitionOrderToStateVariables>(
    TRANSITION_ORDER_TO_STATE,
  )
}

interface AdvanceTransitionOrderToStateParams {
  state: Status
  order: OrderTransform
  refetchQueries?: DocumentNode[]
  awaitRefetchQueries?: boolean
  optimisticResponse?:
    | TransitionOrderToState
    | ((vars: TransitionOrderToStateVariables) => TransitionOrderToState)
    | undefined
}

export const useAdvanceTransitionOrderToState =
  (): UseAdvanceTransitionOrderToState => {
    const [transitionOrderToState, data] = useTransitionOrderToState()

    const handler = async ({
      order,
      state,
      optimisticResponse,
      awaitRefetchQueries,
      refetchQueries,
    }: AdvanceTransitionOrderToStateParams) => {
      const { data, errors } = await transitionOrderToState({
        variables: { id: order.id, state },
        optimisticResponse,
        refetchQueries,
        awaitRefetchQueries,
      })

      if (!data?.transitionOrderToState) {
        throw new Error(
          errors?.[0].message ||
            `failed to transfer the order to the state ${state}`,
        )
      }

      if (
        data?.transitionOrderToState?.__typename === 'OrderStateTransitionError'
      ) {
        throw new Error(
          data.transitionOrderToState.transitionError ||
            data.transitionOrderToState.message,
        )
      }

      return data?.transitionOrderToState
    }

    return [handler, data]
  }

type UseAdvanceTransitionOrderToState = [
  (
    params: AdvanceTransitionOrderToStateParams,
  ) => Promise<TransitionOrderToState_transitionOrderToState_Order | undefined>,
  MutationResult<TransitionOrderToState>,
]
export default useTransitionOrderToState
