import { gql, useMutation } from '@apollo/client'
import { ModifyOrder, ModifyOrderVariables } from './types/ModifyOrder'
import { ORDER_FRAGMENT } from './useOrders'

export const MODIFY_ORDER = gql`
  mutation ModifyOrder(
    $addItems: [AddItemInput!]
    $id: ID!
    $optionsModify: ModifyOrderOptions
    $refund: AdministratorRefundInput
    $dryRun: Boolean = false
    $adjustOrderLines: [AdjustOrderLineInput!]
    $options: HistoryEntryListOptions
  ) {
    modifyOrder(
      input: {
        addItems: $addItems
        dryRun: $dryRun
        orderId: $id
        adjustOrderLines: $adjustOrderLines
        options: $optionsModify
        refund: $refund
      }
    ) {
      ... on ErrorResult {
        errorCode
        message
      }
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`

export const useModifyOrder = () =>
  useMutation<ModifyOrder, ModifyOrderVariables>(MODIFY_ORDER)
