import React, { FC } from 'react'
import withLayout from '../../ui/layout/hoc/withLayout'
import TipTable from './TipTable'
import TipProvider from './TipContext'

const TipScreen: FC = () => {
  return (
    <>
      <TipProvider>
        <TipTable />
      </TipProvider>
    </>
  )
}

export default withLayout(TipScreen)
