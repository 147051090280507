import { Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import React, { useContext } from 'react'
import DatePicker from '../../ui/primitive/components/DatePicker'
import { DashboardContext } from './DashboardContext'

const DashboardHeader = () => {
  const styles = useStyleSheet(themedStyles)
  const { setDateRange, dateRange } = useContext(DashboardContext)

  const onChange = (range) => {
    setDateRange(range)
  }

  const onClear = () => {
    setDateRange({ startDate: new Date(), endDate: new Date() })
  }

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Dashboard</Text>
        <Text style={styles.subtitle}>Sales & Numbers</Text>
      </View>
      <View style={styles.buttonContainer}>
        <DatePicker
          visibleDate={dateRange}
          onCancel={onClear}
          onPush={onChange}
        ></DatePicker>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 22,
    color: '#151A30',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 15,
    color: '#222B45',
  },
  buttonContainer: {
    paddingRight: 30,
  },
})

export default DashboardHeader
