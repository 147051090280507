import React, { FC, useMemo } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import BaseView from '../../primitive/components/BaseView'
import { RootStackParamList } from '../../../types'
import NewOrderCount from '../../primitive/components/NewOrderCount'

const NavItem: FC<INavItem> = React.memo(({ link, name, setOpen }) => {
  const route = useRoute()
  const navigation = useNavigation()
  const active = route.name === link
  const styles = useStyleSheet(themedStyles)

  const clickHandler = () => {
    navigation.navigate(link)
    setOpen()
  }

  const iconName = useMemo(() => {
    switch (name) {
      case 'Sales Dashboard':
        return 'home'
      case 'Orders':
        return 'book-open'
      case 'Tips':
        return 'credit-card'
      case 'Actions':
        return 'shopping-cart'
      case 'Products':
        return 'book-open'
      case 'Settings':
        return 'options'
      case 'Importing':
        return 'file'
      default:
        return 'star'
    }
  }, [name])

  return (
    <Pressable onPress={clickHandler}>
      <BaseView style={[styles.container, active ? styles.active : undefined]}>
        <Icon
          name={iconName}
          fill={active ? '#3366FF' : '#8F9BB3'}
          style={imageStyles.icon}
        />
        <Text style={[styles.text, active ? styles.activeText : undefined]}>
          {name}
        </Text>
        {link === 'Actions' && (
          <View style={styles.newOrderCount}>
            <NewOrderCount />
          </View>
        )}
      </BaseView>
    </Pressable>
  )
})

export default NavItem

interface INavItem {
  link: keyof RootStackParamList
  name: string
  setOpen: () => void
}

const imageStyles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    marginRight: 6,
  },
})

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
  },
  text: {
    fontSize: 15,
    marginTop: 1,
    color: 'textColorPrimary',
  },
  active: {
    backgroundColor: 'activeNavItem',
    borderLeftColor: 'blue',
    borderLeftWidth: 4,
    paddingLeft: 20,
  },
  activeText: {
    color: 'textColorBlue',
  },
  newOrderCount: {
    marginLeft: 27,
  },
})
