import { gql, useMutation } from '@apollo/client'
import { ACTIONS } from './useActions'

export const SET_ACTION = gql`
  mutation setAction($id: Int!) {
    setAction(id: $id, visible: false) {
      id
    }
  }
`

export const useSetAction = () =>
  useMutation(SET_ACTION, { refetchQueries: [ACTIONS] })
