import React, { FC } from 'react'
import { Pressable, Text, View } from 'react-native'
import { dateAgoTransform } from '../../../utils/dateAgoTransform'
import Switch from '../../../ui/primitive/components/Switch'
import { Icon, StyleService } from '@ui-kitten/components'
import useAdaptiveStyles from '../../../hooks/useAdaptiveStyles'
import { OrderTransform } from '../../../utils/orderTransform'
import { Status } from '../../../ui/primitive/components/OrderStatus'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../../ui/primitive/components/Snackbar'
import { useSetOrderCustomFields } from '../../../hooks/useSetOrderCustomFields'
import useTransitionOrderToState from '../../../hooks/useTransitionOrderToState'
import { GET_ORDERS } from '../../../hooks/useOrders'

/** login calling waiter and login wants billing */
const OrderModalHeader: FC<IOrderModalHeader> = ({ order, closeHandler }) => {
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)
  const date = new Date(order.createdAt).toUTCString()

  const [modifyCustomFieldsOrder, { loading }] = useSetOrderCustomFields()
  const [transitionOrderToState] = useTransitionOrderToState()

  const wantsBilling =
    order.state === Status.ARRANGING_PAYMENT && order.waiterPayment

  const wantsBillingHandler = async () => {
    if (loading) return

    try {
      if (order.state !== Status.ARRANGING_PAYMENT) {
        const { data } = await transitionOrderToState({
          variables: {
            id: order.id,
            state: Status.ARRANGING_PAYMENT,
          },
        })

        if (
          data?.transitionOrderToState?.__typename ===
          'OrderStateTransitionError'
        ) {
          throw new Error(data.transitionOrderToState.transitionError)
        }

        await updateCustomField(order, {
          waiterPayment: true,
        })
      } else {
        const lastState = order.history.items[0]?.data?.from

        const { data } = await transitionOrderToState({
          variables: { id: order.id, state: lastState },
          refetchQueries: [GET_ORDERS],
        })

        if (
          data?.transitionOrderToState?.__typename ===
          'OrderStateTransitionError'
        ) {
          throw new Error(data.transitionOrderToState.transitionError)
        }

        await updateCustomField(order, {
          waiterPayment: false,
        })
      }
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }

  const isCallingHandler = async () => {
    if (loading) return

    try {
      await updateCustomField(order, {
        waiterCalling: !order.waiterCalling,
      })
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }

  const updateCustomField = async (
    order: OrderTransform,
    customFields: {
      waiterPayment?: boolean
      waiterCalling?: boolean
    },
  ) => {
    return modifyCustomFieldsOrder({
      variables: {
        input: {
          id: order.id,
          customFields,
        },
      },
      optimisticResponse: {
        setOrderCustomFields: {
          __typename: 'Order',
          id: order.id,
          customFields: {
            waiterCalling: customFields.waiterCalling || order.waiterCalling,
            waiterPayment: customFields.waiterPayment || order.waiterPayment,
            orderPaymentGateway: '',
            seen: true,
            tableId: order.tableId,
            tipp: order.tipp,
            __typename: 'OrderCustomFields',
          },
        },
      },
      refetchQueries: [GET_ORDERS],
      awaitRefetchQueries: true,
    })
  }

  return (
    <View style={styles.header}>
      <View>
        <Text style={styles.title}>
          {`Table ${order.tableId} - ${
            order?.customFields?.customerName || 'Unknown'
          }`}
        </Text>
        <Text style={styles.timeText}>
          {`${date} (${dateAgoTransform(order.createdAt)})`}
        </Text>
      </View>

      <View style={styles.headerActions}>
        <View style={styles.headerActionsItem}>
          <Switch
            toggleSwitch={isCallingHandler}
            isEnabled={order.waiterCalling}
            iconName="bell-outline"
            activeColor="#FFAA00"
          />
          <Text style={styles.headerActionsText}>IS CALLING</Text>
        </View>

        <View>
          <Switch
            toggleSwitch={wantsBillingHandler}
            isEnabled={wantsBilling}
            iconName="bell-outline"
            activeColor="#FFAA00"
          />
          <Text style={styles.headerActionsText}>WANTS BILL</Text>
        </View>
      </View>
      <Pressable style={styles.closeIcon} onPress={closeHandler}>
        <Icon name="close-circle-outline" style={styles.icon} fill="#231F20" />
      </Pressable>
    </View>
  )
}

export default OrderModalHeader

interface IOrderModalHeader {
  order: OrderTransform
  closeHandler: () => void
}

const themedStyles = StyleService.create({
  header: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    flexWrap: 'wrap',
    maxWidth: 500,
  },
  timeText: {
    marginTop: 4,
  },
  headerActions: {
    flexDirection: 'row',
    marginRight: 68,
  },
  headerActionsText: {
    marginTop: 12,
    fontWeight: '700',
    fontSize: 12,
  },
  headerActionsItem: {
    marginRight: 32,
  },
  icon: {
    width: 40,
    height: 40,
  },
  closeIcon: {
    position: 'absolute',
    right: 4,
    top: -8,
  },
})

const themedSmStyles = StyleService.create({
  container: {
    minWidth: '100%',
    paddingVertical: 48,
    paddingHorizontal: 16,
  },
  header: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
    paddingBottom: 28,
  },
  timeText: {
    flexWrap: 'wrap',
    marginTop: 8,
  },
  headerActions: {
    marginTop: 12,
    flexDirection: 'row',
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    flexWrap: 'wrap',
    maxWidth: '80%',
  },
  closeIcon: {
    position: 'absolute',
    top: 4,
    right: -4,
  },
})
