import { Status } from '../ui/primitive/components/OrderStatus'
import { Sorting, tableFields } from '../features/ordersScreen/OrdersTable'
import sortBy from 'lodash/sortBy'
import {
  Orders_orders_items,
  Orders_orders_items_customer,
  Orders_orders_items_history,
  Orders_orders_items_lines,
  Orders_orders_items_payments,
} from '../hooks/types/Orders'
import { CurrencyCode } from '../types/global-types'

export const orderTransform = (
  data: Orders_orders_items[],
  filter?: (typeof tableFields)[number],
  sortingDirection?: Sorting,
): OrderTransform[] => {
  const array = data.map((order) => ({
    ...order,
    seen: order.customFields?.seen || false,
    tipp: order.customFields?.tipp || 0,
    tableId: order.customFields?.tableId || 'Unknown',
    customerName: order.customFields?.customerName || 'Guest',
    status: order.state as Status,
    waiterPayment: !!order.customFields?.waiterPayment,
    waiterCalling: !!order.customFields?.waiterCalling,
  }))

  let sortArray = array
  if (filter === 'Table') {
    sortArray = sortBy(array, [
      (order) =>
        order.customFields?.tableId ? +order.customFields.tableId : undefined,
    ])
  } else if (filter === 'Order ID') {
    sortArray = sortBy(array, [(order) => +order.id])
  } else if (filter === 'Customer') {
    sortArray = sortBy(array, [
      (order) =>
        order.customFields?.customerName
          ? order.customFields?.customerName.toLocaleLowerCase()
          : undefined,
    ])
  } else if (filter === 'Time') {
    sortArray = sortBy(array, [(order) => order.createdAt]).reverse()
  } else if (filter === 'Amount') {
    sortArray = sortBy(array, [(order) => order.totalWithTax])
  } else if (filter === 'Status') {
    sortArray = sortBy(array, [
      (order) => {
        switch (order.status) {
          case Status.ADDING_ITEMS:
            return 0
          case Status.ORDER_PLACED:
            return 1
          case Status.FULFILLMENT:
            return 2
          case Status.ARRANGING_PAYMENT:
            return 3
          case Status.PAYMENT_AUTHORIZED:
            return 4
          case Status.MODIFYING:
            return 5
          case Status.ARRANGING_ADDITIONAL_PAYMENT:
            return 6
          case Status.PAYMENT_SETTLED:
            return 7
          case Status.DELIVERED:
            return 8
          case Status.CANCELLED:
            return 9
          default:
            return 10
        }
      },
    ])
  }

  /** sort direction */
  if (sortingDirection === 'Descending') {
    return sortArray.reverse()
  }

  return sortArray
}

interface CustomFields {
  customerName: string
  tableId: string
  orderPaymentGateway: string
  acceptedOrder: boolean
}

export interface OrderTransform {
  id: string
  seen: boolean
  total: number
  tableId: string
  customerName: string
  tipp: number
  state: Status
  history: Orders_orders_items_history
  waiterPayment: boolean
  waiterCalling: boolean
  totalWithTax: number
  currencyCode: CurrencyCode
  totalQuantity: number
  createdAt: Date
  customFields: CustomFields
  lines: Orders_orders_items_lines[]
  customer: Orders_orders_items_customer | null
  payments: Orders_orders_items_payments[] | null
  status: string
}
