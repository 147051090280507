import React, { useEffect } from 'react'
import { Image, View } from 'react-native'
import {
  Select,
  SelectItem,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import { Status } from '../../ui/primitive/components/OrderStatus'
import icon from '../../assets/images/filterIcon.png'

const statesTitleAndName = [
  {
    title: 'Paid',
    name: Status.PAYMENT_SETTLED,
  },
  {
    title: 'Pay later',
    name: Status.PAY_LATER,
  },
  {
    title: 'Waiting',
    name: Status.WAITING_WAITERS,
  },
  {
    title: 'Canceled by waiter',
    name: Status.CANCELLED,
  },
]
const StateSelectDroplist = ({ setItems }) => {
  const styles = useStyleSheet(themedStyles)
  const [selectedIndex, setSelectedIndex] = React.useState([])

  useEffect(() => {
    const returned = selectedIndex.map(({ row }) => {
      return statesTitleAndName[row].name
    })
    setItems(returned)
  }, [selectedIndex])

  return (
    <View style={styles.container}>
      <Image source={icon} style={styles.icon}></Image>
      <Select
        style={styles.dropList}
        multiSelect={true}
        selectedIndex={selectedIndex}
        placeholder={'Filter by status'}
        value={'Filter by status'}
        onSelect={(index) => setSelectedIndex(index)}
      >
        {statesTitleAndName.map((item) => (
          <SelectItem title={item.title} key={item.name} />
        ))}
      </Select>
    </View>
  )
}

export default StateSelectDroplist

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
  },
  dropList: {
    width: 325,
    marginLeft: 11,
  },
  icon: {
    width: 34,
    height: 34,
  },
})
