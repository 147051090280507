import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'

const NotFoundScreen = () => {
  return (
    <View style={styles.wrapper}>
      <Text>456</Text>
    </View>
  )
}

export default NotFoundScreen

const styles = StyleSheet.create({
  wrapper: {},
  icon: {},
})
