import { StyleService, useStyleSheet } from '@ui-kitten/components'
import React, { FC, useMemo } from 'react'
import { View } from 'react-native'
import LoaderItem, { LoaderItemState } from './LoaderItem'

export enum StatusCodes {
  WAIT = 'WAIT',
  LOAD_FILE = 'LOAD_FILE',
  IMPORTING = 'IMPORTING',
  DONE = 'DONE',
}

const Loader: FC<ILoader> = ({ state }) => {
  const styles = useStyleSheet(themedStyles)

  const uploadItemStatus = useMemo(() => {
    if (state === StatusCodes.LOAD_FILE) return LoaderItemState.PROGRESS
    if (state === StatusCodes.IMPORTING || state === StatusCodes.DONE) {
      return LoaderItemState.DONE
    }
    return LoaderItemState.DISABLED
  }, [state])

  const importItemStatus = useMemo(() => {
    if (state === StatusCodes.IMPORTING) return LoaderItemState.PROGRESS
    if (state === StatusCodes.DONE) return LoaderItemState.DONE
    return LoaderItemState.DISABLED
  }, [state])

  const doneItemStatus = useMemo(() => {
    if (state === StatusCodes.DONE) return LoaderItemState.DONE
    return LoaderItemState.DISABLED
  }, [state])

  return (
    <View style={styles.container}>
      <LoaderItem
        number={1}
        state={LoaderItemState.DONE}
        text={'Select'}
      ></LoaderItem>
      <View style={styles.line} />
      <LoaderItem
        number={2}
        state={uploadItemStatus}
        text={'Upload'}
      ></LoaderItem>
      <View style={styles.line} />
      <LoaderItem
        number={3}
        state={importItemStatus}
        text={'Import'}
      ></LoaderItem>
      <View style={styles.line} />
      <LoaderItem number={4} state={doneItemStatus} text={'Done'}></LoaderItem>
    </View>
  )
}

export default Loader

const themedStyles = StyleService.create({
  container: {
    maxWidth: 459,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    flexDirection: 'row',
  },
  line: {
    width: 40,
    height: 1,
    backgroundColor: 'borderColorPrimary',
    marginBottom: 25,
  },
})

export interface ILoader {
  state: StatusCodes
}
