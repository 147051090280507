import { gql, useQuery } from '@apollo/client'
import { DatePointValue } from '../ui/primitive/components/DatePicker/DatePickerView'

interface TableDataRow {
  name: string
  amount: number
  total: number
}
export const GET_TABLE_DATA = gql`
  query getGroupsSalesData($dateRange: DateRange) {
    getGroupsSalesData(dateRange: $dateRange) {
      name
      amount
      total
    }
  }
`

export const useDashboardTableData = (
  timeRange: DatePointValue,
): Array<TableDataRow> => {
  const { endDate, startDate } = timeRange
  const data = useQuery(GET_TABLE_DATA, {
    variables: { dateRange: { start: startDate, end: endDate } },
  })

  if (data?.data?.getGroupsSalesData) {
    return <Array<TableDataRow>>data.data.getGroupsSalesData
  } else {
    return []
  }
}
