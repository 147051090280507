import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'

const withLayout = (Component: FC) => (props: any) =>
  (
    <BaseLayout>
      <Component {...props} />
    </BaseLayout>
  )

export default withLayout
