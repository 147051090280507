import { dateAgoTransform } from './dateAgoTransform'

const daysNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export function timeFormatter(date: Date) {
  return {
    days: date.getDate(),
    dayName: daysNames[date.getDay() - 1],
    month: date.toLocaleString('default', { month: 'long' }),
    monthShort: date.getMonth(),
    years: date.getFullYear(),
    hours: date.getHours(),
    minutes: String(date.getMinutes()).padStart(2, '0'),
  }
}

export function getLongTimeString(time: Date) {
  const formatted = timeFormatter(time)
  return `${formatted.dayName}, ${formatted.days}.${formatted.monthShort}.${
    formatted.years
  } - ${formatted.hours}:${formatted.minutes} (${dateAgoTransform(time)})`
}
