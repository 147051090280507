import React from 'react'
import { View } from 'react-native'
import { StyleService } from '@ui-kitten/components'
import BaseView, { ViewProps } from '../../primitive/components/BaseView'
import Navbar from './Navbar'
import useAdaptiveStyles from '../../../hooks/useAdaptiveStyles'

export type BaseLayoutProps = ViewProps & { styleContainer?: ViewProps }

const BaseLayout: React.FC<BaseLayoutProps> = ({
  style,
  styleContainer,
  children,
  ...otherProps
}) => {
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)

  return (
    <BaseView {...otherProps} style={[styles.container, styleContainer]}>
      <Navbar />
      <View style={styles.content}>
        <View style={[styles.page, style]}>{children}</View>
      </View>
    </BaseView>
  )
}

export default BaseLayout

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background',
    flexDirection: 'row',
    flex: 1,
  },
  content: {
    marginTop: 39,
    flex: 1,
  },
  page: {
    flex: 1,
    marginLeft: 48,
  },
  smallDevice: {
    padding: 0,
  },
})

const themedSmStyles = StyleService.create({
  content: {
    marginTop: 10,
    padding: 20,
    flex: 1,
  },
  page: {
    flex: 1,
    marginLeft: 0,
  },
})
