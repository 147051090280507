import React, { FC, useContext } from 'react'
import { Pressable, Text } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { OrderEditContext } from './OrderEditContext'

const AddProductButton: FC<IAddProductButton> = React.memo(() => {
  const styles = useStyleSheet(themedStyles)
  const {
    setAddProductMode,
    addingProductMode,
    addNewProduct,
    newProduct,
    setNewProduct,
    editMode,
  } = useContext(OrderEditContext)

  const saveNewProductMode = !!newProduct

  const addProductHandler = async () => {
    if (newProduct && newProduct.quantity) {
      addNewProduct(newProduct)
    }
    setNewProduct(null)
    setAddProductMode(!addingProductMode)
  }

  if (!editMode) {
    return null
  }

  return (
    <Pressable
      style={[styles.container, saveNewProductMode && styles.saveMode]}
      onPress={addProductHandler}
    >
      <Text style={[styles.text, saveNewProductMode && styles.saveModeText]}>
        ADD PRODUCT
      </Text>
    </Pressable>
  )
})

export default AddProductButton

interface IAddProductButton {}

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: 'lightGrey',
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 4,
  },
  text: {
    color: 'textColorSecondary',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  saveMode: {
    backgroundColor: 'blue',
  },
  saveModeText: {
    color: 'textColorWhite',
  },
})
