/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * @description
 * ISO 4217 currency code
 *
 * @docsCategory common
 */
export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum ErrorCode {
  ALREADY_REFUNDED_ERROR = 'ALREADY_REFUNDED_ERROR',
  CANCEL_ACTIVE_ORDER_ERROR = 'CANCEL_ACTIVE_ORDER_ERROR',
  CHANNEL_DEFAULT_LANGUAGE_ERROR = 'CHANNEL_DEFAULT_LANGUAGE_ERROR',
  COUPON_CODE_EXPIRED_ERROR = 'COUPON_CODE_EXPIRED_ERROR',
  COUPON_CODE_INVALID_ERROR = 'COUPON_CODE_INVALID_ERROR',
  COUPON_CODE_LIMIT_ERROR = 'COUPON_CODE_LIMIT_ERROR',
  CREATE_FULFILLMENT_ERROR = 'CREATE_FULFILLMENT_ERROR',
  EMAIL_ADDRESS_CONFLICT_ERROR = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  EMPTY_ORDER_LINE_SELECTION_ERROR = 'EMPTY_ORDER_LINE_SELECTION_ERROR',
  FULFILLMENT_STATE_TRANSITION_ERROR = 'FULFILLMENT_STATE_TRANSITION_ERROR',
  INSUFFICIENT_STOCK_ERROR = 'INSUFFICIENT_STOCK_ERROR',
  INSUFFICIENT_STOCK_ON_HAND_ERROR = 'INSUFFICIENT_STOCK_ON_HAND_ERROR',
  INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR',
  INVALID_FULFILLMENT_HANDLER_ERROR = 'INVALID_FULFILLMENT_HANDLER_ERROR',
  ITEMS_ALREADY_FULFILLED_ERROR = 'ITEMS_ALREADY_FULFILLED_ERROR',
  LANGUAGE_NOT_AVAILABLE_ERROR = 'LANGUAGE_NOT_AVAILABLE_ERROR',
  MANUAL_PAYMENT_STATE_ERROR = 'MANUAL_PAYMENT_STATE_ERROR',
  MIME_TYPE_ERROR = 'MIME_TYPE_ERROR',
  MISSING_CONDITIONS_ERROR = 'MISSING_CONDITIONS_ERROR',
  MULTIPLE_ORDER_ERROR = 'MULTIPLE_ORDER_ERROR',
  NATIVE_AUTH_STRATEGY_ERROR = 'NATIVE_AUTH_STRATEGY_ERROR',
  NEGATIVE_QUANTITY_ERROR = 'NEGATIVE_QUANTITY_ERROR',
  NOTHING_TO_REFUND_ERROR = 'NOTHING_TO_REFUND_ERROR',
  NO_CHANGES_SPECIFIED_ERROR = 'NO_CHANGES_SPECIFIED_ERROR',
  ORDER_LIMIT_ERROR = 'ORDER_LIMIT_ERROR',
  ORDER_MODIFICATION_STATE_ERROR = 'ORDER_MODIFICATION_STATE_ERROR',
  ORDER_STATE_TRANSITION_ERROR = 'ORDER_STATE_TRANSITION_ERROR',
  PAYMENT_METHOD_MISSING_ERROR = 'PAYMENT_METHOD_MISSING_ERROR',
  PAYMENT_ORDER_MISMATCH_ERROR = 'PAYMENT_ORDER_MISMATCH_ERROR',
  PAYMENT_STATE_TRANSITION_ERROR = 'PAYMENT_STATE_TRANSITION_ERROR',
  PRODUCT_OPTION_IN_USE_ERROR = 'PRODUCT_OPTION_IN_USE_ERROR',
  QUANTITY_TOO_GREAT_ERROR = 'QUANTITY_TOO_GREAT_ERROR',
  REFUND_ORDER_STATE_ERROR = 'REFUND_ORDER_STATE_ERROR',
  REFUND_PAYMENT_ID_MISSING_ERROR = 'REFUND_PAYMENT_ID_MISSING_ERROR',
  REFUND_STATE_TRANSITION_ERROR = 'REFUND_STATE_TRANSITION_ERROR',
  SETTLE_PAYMENT_ERROR = 'SETTLE_PAYMENT_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

/**
 * @description
 * Languages in the form of a ISO 639-1 language code with optional
 * region or script modifier (e.g. de_AT). The selection available is based
 * on the [Unicode CLDR summary list](https: // unicode-org.github.io/cldr-staging/charts/37/summary/root.html)
 * and includes the major spoken languages of the world and any widely-used variants.
 *
 * @docsCategory common
 */
export enum LanguageCode {
  af = 'af',
  ak = 'ak',
  am = 'am',
  ar = 'ar',
  as = 'as',
  az = 'az',
  be = 'be',
  bg = 'bg',
  bm = 'bm',
  bn = 'bn',
  bo = 'bo',
  br = 'br',
  bs = 'bs',
  ca = 'ca',
  ce = 'ce',
  co = 'co',
  cs = 'cs',
  cu = 'cu',
  cy = 'cy',
  da = 'da',
  de = 'de',
  de_AT = 'de_AT',
  de_CH = 'de_CH',
  dz = 'dz',
  ee = 'ee',
  el = 'el',
  en = 'en',
  en_AU = 'en_AU',
  en_CA = 'en_CA',
  en_GB = 'en_GB',
  en_US = 'en_US',
  eo = 'eo',
  es = 'es',
  es_ES = 'es_ES',
  es_MX = 'es_MX',
  et = 'et',
  eu = 'eu',
  fa = 'fa',
  fa_AF = 'fa_AF',
  ff = 'ff',
  fi = 'fi',
  fo = 'fo',
  fr = 'fr',
  fr_CA = 'fr_CA',
  fr_CH = 'fr_CH',
  fy = 'fy',
  ga = 'ga',
  gd = 'gd',
  gl = 'gl',
  gu = 'gu',
  gv = 'gv',
  ha = 'ha',
  he = 'he',
  hi = 'hi',
  hr = 'hr',
  ht = 'ht',
  hu = 'hu',
  hy = 'hy',
  ia = 'ia',
  id = 'id',
  ig = 'ig',
  ii = 'ii',
  is = 'is',
  it = 'it',
  ja = 'ja',
  jv = 'jv',
  ka = 'ka',
  ki = 'ki',
  kk = 'kk',
  kl = 'kl',
  km = 'km',
  kn = 'kn',
  ko = 'ko',
  ks = 'ks',
  ku = 'ku',
  kw = 'kw',
  ky = 'ky',
  la = 'la',
  lb = 'lb',
  lg = 'lg',
  ln = 'ln',
  lo = 'lo',
  lt = 'lt',
  lu = 'lu',
  lv = 'lv',
  mg = 'mg',
  mi = 'mi',
  mk = 'mk',
  ml = 'ml',
  mn = 'mn',
  mr = 'mr',
  ms = 'ms',
  mt = 'mt',
  my = 'my',
  nb = 'nb',
  nd = 'nd',
  ne = 'ne',
  nl = 'nl',
  nl_BE = 'nl_BE',
  nn = 'nn',
  ny = 'ny',
  om = 'om',
  or = 'or',
  os = 'os',
  pa = 'pa',
  pl = 'pl',
  ps = 'ps',
  pt = 'pt',
  pt_BR = 'pt_BR',
  pt_PT = 'pt_PT',
  qu = 'qu',
  rm = 'rm',
  rn = 'rn',
  ro = 'ro',
  ro_MD = 'ro_MD',
  ru = 'ru',
  rw = 'rw',
  sa = 'sa',
  sd = 'sd',
  se = 'se',
  sg = 'sg',
  si = 'si',
  sk = 'sk',
  sl = 'sl',
  sm = 'sm',
  sn = 'sn',
  so = 'so',
  sq = 'sq',
  sr = 'sr',
  st = 'st',
  su = 'su',
  sv = 'sv',
  sw = 'sw',
  sw_CD = 'sw_CD',
  ta = 'ta',
  te = 'te',
  tg = 'tg',
  th = 'th',
  ti = 'ti',
  tk = 'tk',
  to = 'to',
  tr = 'tr',
  tt = 'tt',
  ug = 'ug',
  uk = 'uk',
  ur = 'ur',
  uz = 'uz',
  vi = 'vi',
  vo = 'vo',
  wo = 'wo',
  xh = 'xh',
  yi = 'yi',
  yo = 'yo',
  zh = 'zh',
  zh_Hans = 'zh_Hans',
  zh_Hant = 'zh_Hant',
  zu = 'zu',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

/**
 * @description
 * Permissions for administrators and customers. Used to control access to
 * GraphQL resolvers via the {@link Allow} decorator.
 *
 * ## Understanding Permission.Owner
 *
 * `Permission.Owner` is a special permission which is used in some of the Vendure resolvers to indicate that that resolver should only
 * be accessible to the "owner" of that resource.
 *
 * For example, the Shop API `activeCustomer` query resolver should only return the Customer object for the "owner" of that Customer, i.e.
 * based on the activeUserId of the current session. As a result, the resolver code looks like this:
 *
 * @example
 * ```TypeScript
 * \@Query()
 * \@Allow(Permission.Owner)
 * async activeCustomer(\@Ctx() ctx: RequestContext): Promise<Customer | undefined> {
 *   const userId = ctx.activeUserId;
 *   if (userId) {
 *     return this.customerService.findOneByUserId(ctx, userId);
 *   }
 * }
 * ```
 *
 * Here we can see that the "ownership" must be enforced by custom logic inside the resolver. Since "ownership" cannot be defined generally
 * nor statically encoded at build-time, any resolvers using `Permission.Owner` **must** include logic to enforce that only the owner
 * of the resource has access. If not, then it is the equivalent of using `Permission.Public`.
 *
 *
 * @docsCategory common
 */
export enum Permission {
  Authenticated = 'Authenticated',
  CreateAdministrator = 'CreateAdministrator',
  CreateAsset = 'CreateAsset',
  CreateCatalog = 'CreateCatalog',
  CreateChannel = 'CreateChannel',
  CreateCollection = 'CreateCollection',
  CreateCountry = 'CreateCountry',
  CreateCustomer = 'CreateCustomer',
  CreateCustomerGroup = 'CreateCustomerGroup',
  CreateFacet = 'CreateFacet',
  CreateOrder = 'CreateOrder',
  CreatePaymentMethod = 'CreatePaymentMethod',
  CreateProduct = 'CreateProduct',
  CreatePromotion = 'CreatePromotion',
  CreateSettings = 'CreateSettings',
  CreateShippingMethod = 'CreateShippingMethod',
  CreateSystem = 'CreateSystem',
  CreateTag = 'CreateTag',
  CreateTaxCategory = 'CreateTaxCategory',
  CreateTaxRate = 'CreateTaxRate',
  CreateZone = 'CreateZone',
  DeleteAdministrator = 'DeleteAdministrator',
  DeleteAsset = 'DeleteAsset',
  DeleteCatalog = 'DeleteCatalog',
  DeleteChannel = 'DeleteChannel',
  DeleteCollection = 'DeleteCollection',
  DeleteCountry = 'DeleteCountry',
  DeleteCustomer = 'DeleteCustomer',
  DeleteCustomerGroup = 'DeleteCustomerGroup',
  DeleteFacet = 'DeleteFacet',
  DeleteOrder = 'DeleteOrder',
  DeletePaymentMethod = 'DeletePaymentMethod',
  DeleteProduct = 'DeleteProduct',
  DeletePromotion = 'DeletePromotion',
  DeleteSettings = 'DeleteSettings',
  DeleteShippingMethod = 'DeleteShippingMethod',
  DeleteSystem = 'DeleteSystem',
  DeleteTag = 'DeleteTag',
  DeleteTaxCategory = 'DeleteTaxCategory',
  DeleteTaxRate = 'DeleteTaxRate',
  DeleteZone = 'DeleteZone',
  Owner = 'Owner',
  Public = 'Public',
  ReadAdministrator = 'ReadAdministrator',
  ReadAsset = 'ReadAsset',
  ReadCatalog = 'ReadCatalog',
  ReadChannel = 'ReadChannel',
  ReadCollection = 'ReadCollection',
  ReadCountry = 'ReadCountry',
  ReadCustomer = 'ReadCustomer',
  ReadCustomerGroup = 'ReadCustomerGroup',
  ReadFacet = 'ReadFacet',
  ReadOrder = 'ReadOrder',
  ReadPaymentMethod = 'ReadPaymentMethod',
  ReadProduct = 'ReadProduct',
  ReadPromotion = 'ReadPromotion',
  ReadSettings = 'ReadSettings',
  ReadShippingMethod = 'ReadShippingMethod',
  ReadSystem = 'ReadSystem',
  ReadTag = 'ReadTag',
  ReadTaxCategory = 'ReadTaxCategory',
  ReadTaxRate = 'ReadTaxRate',
  ReadZone = 'ReadZone',
  SuperAdmin = 'SuperAdmin',
  UpdateAdministrator = 'UpdateAdministrator',
  UpdateAsset = 'UpdateAsset',
  UpdateCatalog = 'UpdateCatalog',
  UpdateChannel = 'UpdateChannel',
  UpdateCollection = 'UpdateCollection',
  UpdateCountry = 'UpdateCountry',
  UpdateCustomer = 'UpdateCustomer',
  UpdateCustomerGroup = 'UpdateCustomerGroup',
  UpdateFacet = 'UpdateFacet',
  UpdateGlobalSettings = 'UpdateGlobalSettings',
  UpdateOrder = 'UpdateOrder',
  UpdatePaymentMethod = 'UpdatePaymentMethod',
  UpdateProduct = 'UpdateProduct',
  UpdatePromotion = 'UpdatePromotion',
  UpdateSettings = 'UpdateSettings',
  UpdateShippingMethod = 'UpdateShippingMethod',
  UpdateSystem = 'UpdateSystem',
  UpdateTag = 'UpdateTag',
  UpdateTaxCategory = 'UpdateTaxCategory',
  UpdateTaxRate = 'UpdateTaxRate',
  UpdateZone = 'UpdateZone',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface AddItemInput {
  productVariantId: string
  quantity: number
}

export interface AdjustOrderLineInput {
  orderLineId: string
  quantity: number
}

export interface AdministratorRefundInput {
  paymentId: string
  reason?: string | null
}

/**
 * Operators for filtering on a Boolean field
 */
export interface BooleanOperators {
  eq?: boolean | null
}

export interface CancelOrderInput {
  orderId: string
  lines?: OrderLineInput[] | null
  cancelShipping?: boolean | null
  reason?: string | null
}

/**
 * Operators for filtering on a DateTime field
 */
export interface DateOperators {
  eq?: any | null
  before?: any | null
  after?: any | null
  between?: DateRange | null
}

export interface DateRange {
  start: any
  end: any
}

export interface HistoryEntryFilterParameter {
  isPublic?: BooleanOperators | null
  id?: IDOperators | null
  createdAt?: DateOperators | null
  updatedAt?: DateOperators | null
  type?: StringOperators | null
}

export interface HistoryEntryListOptions {
  skip?: number | null
  take?: number | null
  sort?: HistoryEntrySortParameter | null
  filter?: HistoryEntryFilterParameter | null
  filterOperator?: LogicalOperator | null
}

export interface HistoryEntrySortParameter {
  id?: SortOrder | null
  createdAt?: SortOrder | null
  updatedAt?: SortOrder | null
}

/**
 * Operators for filtering on an ID field
 */
export interface IDOperators {
  eq?: string | null
  notEq?: string | null
  in?: string[] | null
  notIn?: string[] | null
}

export interface ManualPaymentInput {
  orderId: string
  method: string
  transactionId?: string | null
  metadata?: any | null
}

export interface ModifyOrderOptions {
  freezePromotions?: boolean | null
  recalculateShipping?: boolean | null
}

/**
 * Operators for filtering on a Int or Float field
 */
export interface NumberOperators {
  eq?: number | null
  lt?: number | null
  lte?: number | null
  gt?: number | null
  gte?: number | null
  between?: NumberRange | null
}

export interface NumberRange {
  start: number
  end: number
}

export interface OrderFilterParameter {
  customerLastName?: StringOperators | null
  id?: IDOperators | null
  createdAt?: DateOperators | null
  updatedAt?: DateOperators | null
  orderPlacedAt?: DateOperators | null
  code?: StringOperators | null
  state?: StringOperators | null
  active?: BooleanOperators | null
  totalQuantity?: NumberOperators | null
  subTotal?: NumberOperators | null
  subTotalWithTax?: NumberOperators | null
  currencyCode?: StringOperators | null
  shipping?: NumberOperators | null
  shippingWithTax?: NumberOperators | null
  total?: NumberOperators | null
  totalWithTax?: NumberOperators | null
  orderPaymentGateway?: StringOperators | null
  tipp?: NumberOperators | null
  tableId?: StringOperators | null
  waiterPayment?: BooleanOperators | null
  waiterCalling?: BooleanOperators | null
  seen?: BooleanOperators | null
}

export interface OrderLineInput {
  orderLineId: string
  quantity: number
}

export interface OrderListOptions {
  skip?: number | null
  take?: number | null
  sort?: OrderSortParameter | null
  filter?: OrderFilterParameter | null
  filterOperator?: LogicalOperator | null
}

export interface OrderSortParameter {
  customerLastName?: SortOrder | null
  id?: SortOrder | null
  createdAt?: SortOrder | null
  updatedAt?: SortOrder | null
  orderPlacedAt?: SortOrder | null
  code?: SortOrder | null
  state?: SortOrder | null
  totalQuantity?: SortOrder | null
  subTotal?: SortOrder | null
  subTotalWithTax?: SortOrder | null
  shipping?: SortOrder | null
  shippingWithTax?: SortOrder | null
  total?: SortOrder | null
  totalWithTax?: SortOrder | null
  orderPaymentGateway?: SortOrder | null
  tipp?: SortOrder | null
  tableId?: SortOrder | null
  waiterPayment?: SortOrder | null
  waiterCalling?: SortOrder | null
  seen?: SortOrder | null
}

export interface PaymentMethodFilterParameter {
  id?: IDOperators | null
  createdAt?: DateOperators | null
  updatedAt?: DateOperators | null
  name?: StringOperators | null
  code?: StringOperators | null
  description?: StringOperators | null
  enabled?: BooleanOperators | null
}

export interface PaymentMethodListOptions {
  skip?: number | null
  take?: number | null
  sort?: PaymentMethodSortParameter | null
  filter?: PaymentMethodFilterParameter | null
  filterOperator?: LogicalOperator | null
}

export interface PaymentMethodSortParameter {
  id?: SortOrder | null
  createdAt?: SortOrder | null
  updatedAt?: SortOrder | null
  name?: SortOrder | null
  code?: SortOrder | null
  description?: SortOrder | null
}

export interface RefundOrderInput {
  lines: OrderLineInput[]
  shipping: number
  adjustment: number
  paymentId: string
  reason?: string | null
}

export interface SettleRefundInput {
  id: string
  transactionId: string
}

/**
 * Operators for filtering on a String field
 */
export interface StringOperators {
  eq?: string | null
  notEq?: string | null
  contains?: string | null
  notContains?: string | null
  in?: string[] | null
  notIn?: string[] | null
  regex?: string | null
}

export interface UpdateChannelCustomFieldsInput {
  channelState?: string | null
  logo?: string | null
  address?: string | null
  phone?: string | null
  colour?: string | null
}

export interface UpdateChannelInput {
  id: string
  code?: string | null
  token?: string | null
  defaultLanguageCode?: LanguageCode | null
  pricesIncludeTax?: boolean | null
  currencyCode?: CurrencyCode | null
  defaultTaxZoneId?: string | null
  defaultShippingZoneId?: string | null
  customFields?: UpdateChannelCustomFieldsInput | null
}

export interface UpdateOrderCustomFieldsInput {
  orderPaymentGateway?: string | null
  tipp?: number | null
  tableId?: string | null
  waiterPayment?: boolean | null
  waiterCalling?: boolean | null
  seen?: boolean | null
  acceptedOrder: boolean | null
}

export interface UpdateOrderInput {
  id: string
  customFields?: UpdateOrderCustomFieldsInput | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
