import { Pressable, View, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Modal, StyleService, useStyleSheet } from '@ui-kitten/components'
import DatePickerView, { DatePointValue } from './DatePickerView'
import { formatDateForString, formatDateRange } from './helpers'

const DatePicker = ({ onPush, onCancel: onCancelHandler, visibleDate }) => {
  const styles = useStyleSheet(themedStyles)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })

  useEffect(() => {
    if (visibleDate) {
      setDate(visibleDate)
    }
  }, [visibleDate])

  const onPushed = (data: DatePointValue) => {
    setIsOpen(false)
    const formattedDate = formatDateRange(data)
    setDate(formattedDate)
    onPush(formattedDate)
  }

  const onCancel = () => {
    setIsOpen(false)
    onCancelHandler()
  }

  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          setIsOpen(!isOpen)
        }}
        style={styles.pressable}
      >
        <View style={[styles.label, styles.firstInput]}>
          <Text style={styles.text}>{formatDateForString(data.startDate)}</Text>
        </View>
        <View style={styles.separator}></View>
        <View style={[styles.label, styles.secondInput]}>
          <Text style={styles.text}>{formatDateForString(data.endDate)}</Text>
        </View>
      </Pressable>
      <Modal visible={isOpen}>
        <DatePickerView onPush={onPushed} onCancel={onCancel}></DatePickerView>
      </Modal>
    </View>
  )
}

export default DatePicker

const themedStyles = StyleService.create({
  container: {
    borderColor: 'activeNavItem',
    border: '2px solid',
    borderRadius: 25,
  },
  pressable: {
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  label: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  firstInput: {},
  secondInput: {},
  separator: {
    borderColor: 'activeNavItem',
    border: '1px solid',
  },
  text: {
    fontFamily: 'open-sans-semibold',
    fontSize: 17,
  },
})
