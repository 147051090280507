import * as eva from '@eva-design/eva'
import { ApolloProvider } from '@apollo/client'
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components'
import { EvaIconsPack } from '@ui-kitten/eva-icons'
import Navigation from './navigation'
import React, { useEffect } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import appTheme from './constants/Colors'
import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import Snackbar from './ui/primitive/components/Snackbar'
import Modal from './ui/primitive/components/Modal'
import { createApolloClient } from './apolloClient'
import Constants from 'expo-constants'
import OneSignal from 'react-onesignal'

export default function App() {
  const isLoadingComplete = useCachedResources()
  const theme = useColorScheme()

  useEffect(() => {
    OneSignal.init({
      appId: Constants.manifest?.extra?.ONE_SIGNAL_APP_ID,
    })
      .then((resp) => {
        console.log(resp)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  if (!isLoadingComplete) return null

  return (
    <ApolloProvider client={createApolloClient()}>
      <SafeAreaProvider>
        <IconRegistry icons={EvaIconsPack} />
        <ApplicationProvider
          {...eva}
          theme={{ ...appTheme[theme], ...eva[theme] }}
        >
          <Navigation />
          <Snackbar />
          <Modal />
        </ApplicationProvider>
      </SafeAreaProvider>
    </ApolloProvider>
  )
}
