export const ExampleRows = (code: string) => [
  {
    name: 'Espresso',
    slug: 'espresso',
    description: 'It is Coffee',
    assets: '',
    facets: code + ':Coffee',
    optionGroups: '',
    optionValues: '',
    sku: '101',
    price: '4.50',
    taxCategory: 'standard',
    stockOnHand: '1000000',
    trackInventory: '',
    variantAssets: '',
    variantFacets: '',
  },
  {
    name: 'Espresso Macchiato',
    slug: 'espresso-macchiato',
    description: 'It is Espresso',
    assets: '',
    facets: code + ':Coffee',
    optionGroups: 'Milch',
    optionValues: 'Kuhmilch',
    sku: 'KUH102',
    price: '5.00',
    taxCategory: 'standard',
    stockOnHand: '1000000',
    trackInventory: '',
    variantAssets: '',
    variantFacets: '',
  },
  {
    name: '',
    slug: '',
    description: '',
    assets: '',
    facets: '',
    optionGroups: '',
    optionValues: 'Hafermilch',
    sku: 'HAFER102',
    price: '5.50',
    taxCategory: 'standard',
    stockOnHand: '1000000',
    trackInventory: '',
    variantAssets: '',
    variantFacets: '',
  },
]

export const ExampleText = (channelCode: string) =>
  'name,slug,description,assets,facets,optionGroups,optionValues,sku,price,taxCategory,stockOnHand,trackInventory,variantAssets,variantFacets\n' +
  'Espresso,espresso,,icons/011-coffee-cup.svg,' +
  channelCode +
  ':Coffee,,,101,4.5,standard,1000000,,,\n' +
  'Espresso Macchiato,espresso-macchiato,,icons/011-coffee-cup.svg,' +
  channelCode +
  ':Coffee,Milch,Kuhmilch,KUH102,5,standard,1000000,,,\n' +
  ',,,,,,Hafermilch,HAFER102,5.5,standard,1000000,,,\n'
