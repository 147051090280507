import { useStyleSheet } from '@ui-kitten/components'
import { useWindowDimensions } from 'react-native'

const useAdaptiveStyles = <T, T2>(
  themedStyles: T,
  themedSmStyles: T2,
): (T & T2) | T => {
  const desktopStyles: T = useStyleSheet(themedStyles)
  const mobileStyles: T2 = useStyleSheet(themedSmStyles)

  const { width } = useWindowDimensions()
  const isSmallDevice = width < 900
  return isSmallDevice ? { ...desktopStyles, ...mobileStyles } : desktopStyles
}

export default useAdaptiveStyles
