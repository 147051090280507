import { gql, useMutation } from '@apollo/client'
import { SetOrderSeen, SetOrderSeenVariables } from './types/SetOrderSeen'

const SET_ORDER_CUSTOM_FIELD = gql`
  mutation SetOrderSeen($id: ID!) {
    setOrderCustomFields(input: { id: $id, customFields: { seen: true } }) {
      id
      customFields {
        seen
      }
    }
  }
`

export const useViewOrder = () =>
  useMutation<SetOrderSeen, SetOrderSeenVariables>(SET_ORDER_CUSTOM_FIELD)
