import React, { useState } from 'react'
import { View, Text } from 'react-native'
import {
  Button,
  Card,
  Modal,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import { useVisitAllActions } from '../../hooks/useVisitAllActions'

const ActionHeader = () => {
  const styles = useStyleSheet(themedStyles)
  const [visible, setVisible] = useState(false)
  const [handler] = useVisitAllActions()

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const onPress = () => {
    setVisible(false)
    handler()
  }

  return (
    <>
      <Modal
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={closeModal}
      >
        <Card disabled={false} style={styles.card}>
          <View style={styles.modalContent}>
            <Text style={styles.clearText}>
              Are you sure to clear the action list?
            </Text>
            <View style={styles.buttonsContainer}>
              <Button appearance={'filled'} status={'info'} onPress={onPress}>
                YES
              </Button>
              <Button
                appearance={'filled'}
                status={'info'}
                onPress={closeModal}
              >
                NO
              </Button>
            </View>
          </View>
        </Card>
      </Modal>
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>ACTION LIST</Text>
          <Text style={styles.subtitle}>CUSTOMER TODO LIST</Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button appearance={'filled'} status={'info'} onPress={openModal}>
            <Text style={styles.buttonText}>CLEAR ACTION LIST</Text>
          </Button>
        </View>
      </View>
    </>
  )
}

export default ActionHeader

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 22,
    color: '#151A30',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 15,
    color: '#222B45',
  },
  buttonContainer: {
    paddingRight: 30,
  },
  button: {},
  buttonText: {
    textTransform: 'uppercase',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  clearText: {
    textTransform: 'uppercase',
    fontSize: 18,
    color: '#151A30',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 25,
  },
  modalContent: {
    width: 300,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-between',
  },
  card: {
    backgroundColor: '#fff',
  },
})
