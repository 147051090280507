import React, { FC, useMemo } from 'react'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { View, Text } from 'react-native'
import priceTransform from '../../utils/priceTransform'

const DashboardTableRow: FC<IDashboardTableRow> = ({
  amount,
  total,
  name,
  id,
}) => {
  const styles = useStyleSheet(themedStyles)

  const isWhite = useMemo(() => {
    return !(id % 2)
  }, [id])

  const formattedTotal = useMemo(() => {
    return priceTransform(total)
  }, [total])

  return (
    <View style={styles.container}>
      <View style={[styles.cell, styles.bigCell, isWhite && styles.whiteCell]}>
        <Text style={styles.text}>{name}</Text>
      </View>
      <View
        style={[styles.cell, styles.smallCell, isWhite && styles.whiteCell]}
      >
        <Text style={styles.text}>{amount}</Text>
      </View>
      <View style={[styles.cell, styles.bigCell, isWhite && styles.whiteCell]}>
        <Text style={styles.text}>{formattedTotal}</Text>
      </View>
    </View>
  )
}

export default DashboardTableRow

const themedStyles = StyleService.create({
  container: {
    width: 375,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'modalLightGrey',
  },
  cell: {
    height: 53,
    justifyContent: 'center',
    alignItems: 'center',
  },
  whiteCell: {
    backgroundColor: 'viewBgColor',
  },
  bigCell: {
    width: 139,
  },
  smallCell: {
    width: 93,
  },
  text: {
    fontFamily: 'open-sans-regular',
    fontSize: 15,
  },
})
interface IDashboardTableRow {
  amount: number
  name: string
  total: number
  id: number
}
