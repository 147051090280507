import { gql, useQuery } from '@apollo/client'

export const ACTIONS = gql`
  query getActions {
    getActions {
      tableId
      status
      id
      visible
      orderId
      timeStamp
    }
  }
`

const useActions = () => {
  const data = useQuery(ACTIONS, { pollInterval: 2000 })
  return { ...data, data: data.data?.getActions || [] }
}

export default useActions
