import { useEffect } from 'react'
import { GET_ORDERS } from './useOrders'
import { useViewOrder } from './useViewOrder'
import { OrderTransform } from '../utils/orderTransform'

const useSeenOrder = (order: OrderTransform) => {
  const [viewOrder, { data, loading }] = useViewOrder()

  useEffect(() => {
    async function load() {
      try {
        await viewOrder({
          variables: { id: order.id },
          refetchQueries: [GET_ORDERS],
        })
      } catch (e) {
        console.error(e)
      }
    }

    if (
      !order.seen &&
      !loading &&
      !data?.setOrderCustomFields?.customFields?.seen
    ) {
      load()
    }
  }, [order.id, loading, data, order.seen, viewOrder])
}

export default useSeenOrder
