import React, { FC } from 'react'
import { View, Text } from 'react-native'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'

export enum LoaderItemState {
  DONE = 'DONE',
  PROGRESS = 'PROGRESS',
  DISABLED = 'DISABLED',
}
const LoaderItem: FC<ILoaderItem> = ({ state, text, number }) => {
  const styles = useStyleSheet(themedStyles)

  const isDone = state === LoaderItemState.DONE
  const isProgress = state === LoaderItemState.PROGRESS
  const isDisabled = state === LoaderItemState.DISABLED

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.circle,
          isDone && styles.circleDone,
          isProgress && styles.circleProgress,
        ]}
      >
        {isDone ? (
          <Icon fill={'#fff'} name={'checkmark'} style={styles.icon} />
        ) : (
          <Text style={[styles.text, isProgress && styles.textProgress]}>
            {number}
          </Text>
        )}
      </View>
      <Text
        style={[styles.number, (isProgress || isDone) && styles.textProgress]}
      >
        {text}
      </Text>
    </View>
  )
}

export default LoaderItem

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'disabledColor',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleProgress: {
    backgroundColor: 'activeNavItem',
    borderColor: 'textColorBlue',
  },
  circleDone: {
    borderColor: 'textColorBlue',
    backgroundColor: 'textColorBlue',
  },
  text: {
    fontSize: 12,
    color: 'disabledColor',
    fontFamily: 'open-sans-bold',
    paddingRight: 1,
  },
  textProgress: {
    color: 'textColorBlue',
  },
  number: {
    fontFamily: 'open-sans-regular',
    fontSize: 12,
    color: 'disabledColor',
  },
  icon: {
    width: 13,
    height: 13,
  },
})

interface ILoaderItem {
  state: LoaderItemState
  text: string
  number: number
}
