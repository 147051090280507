import {
  gql,
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client'
import {
  ProductSelectorSearch,
  ProductSelectorSearch_search_items,
  ProductSelectorSearchVariables,
} from './types/ProductSelectorSearch'

export const GET_PRODUCT_SELECTOR_SEARCH = gql`
  query ProductSelectorSearch($term: String!) {
    search(input: { groupByProduct: false, term: $term, take: 10 }) {
      items {
        productVariantId
        productVariantName
        score
        sku
        priceWithTax {
          ... on SinglePrice {
            value
          }
          ... on PriceRange {
            max
          }
        }
        currencyCode
      }
    }
  }
`

export const useProductSelectorSearch = (): [
  (
    options?: QueryLazyOptions<ProductSelectorSearchVariables> | undefined,
  ) => void,
  ResultData,
] => {
  const [search, data] = useLazyQuery<
    ProductSelectorSearch,
    ProductSelectorSearchVariables
  >(GET_PRODUCT_SELECTOR_SEARCH, {
    fetchPolicy: 'cache-first',
  })

  return [search, { ...data, data: data.data?.search.items || [] }]
}

type ResultData = Omit<
  LazyQueryResult<ProductSelectorSearch, ProductSelectorSearchVariables>,
  'data'
> & {
  data: ProductSelectorSearch_search_items[]
}
