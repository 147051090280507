import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import Constants from 'expo-constants'
import { snackbarStateVar } from './ui/primitive/components/Snackbar'
import { modalStateVar } from './ui/primitive/components/Modal'
import { GET_ME } from './hooks/useMe'

export const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: `${Constants.manifest?.extra?.API_URL}/admin-api`,
    credentials: 'include',
  })

  const channelAwareRequest = new ApolloLink((operation, forward) => {
    const { cache } = operation.getContext()

    const result = cache.readQuery({
      query: GET_ME,
    })
    let uri = `${Constants.manifest?.extra?.API_URL}/admin-api`
    const token = result?.me?.channels?.[0]?.token
    if (token) {
      uri = `${uri}?vendure-token=${result?.me?.channels?.[0]?.token}`
    }
    operation.setContext({
      uri,
    })
    return forward(operation)
  })

  return new ApolloClient({
    link: from([channelAwareRequest.concat(httpLink)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            snackbarState: {
              read() {
                return snackbarStateVar()
              },
            },
            modalState: {
              read() {
                return modalStateVar()
              },
            },
          },
        },
      },
    }),
    credentials: 'include',
  })
}
