import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import React, { useCallback, useMemo } from 'react'
import { GestureResponderEvent, Text, View } from 'react-native'
import { useMe } from '../../hooks/useMe'
import { A } from '@expo/html-elements'

const DownloadTicket = () => {
  const styles = useStyleSheet(themedStyles)
  const { data } = useMe()

  const downLoadUrl = useMemo(() => {
    const channel = data?.me?.channels[0]
    return `${process.env.API_URL}/import/export-products?vendure-token=${
      channel?.token || ''
    }`
  }, [data])

  const download = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault()
      event.stopPropagation()
      fetch(downLoadUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.download = 'export.csv'
          a.href = blobUrl
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
    },
    [downLoadUrl],
  )

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <Icon
          style={styles.infoIcon}
          fill={'#3366ff'}
          name={'alert-circle'}
        ></Icon>
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.textHeader}>
          Download your existing product catalog
        </Text>
        <Text style={styles.firstText}>
          You can download your product catalog for editing in Excel, to make
          your changes and upload it again
        </Text>
        <Text style={styles.secondText}>
          Keep in mind to save/export your edited catalog in CSV-Format
          (comma-separated)
        </Text>
      </View>
      <View style={styles.rightSide}>
        <View style={styles.downloadContainer}>
          <Icon
            style={styles.downloadIcon}
            fill={'#3366ff'}
            name={'attach-2'}
          ></Icon>
          <A style={styles.downloadLink} href={downLoadUrl} onPress={download}>
            <Text style={styles.linkText}>Download catalog</Text>
          </A>
        </View>
      </View>
    </View>
  )
}

export default DownloadTicket

const themedStyles = StyleService.create({
  container: {
    backgroundColor: '#fff',
    maxWidth: 794,
    width: 794,
    flexDirection: 'row',
    paddingVertical: 18,
    alignItems: 'center',
    marginTop: 30,
  },
  infoContainer: {
    width: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
  textContainer: {
    width: 597,
  },
  textHeader: {
    color: 'textColorPrimary',
    fontSize: 15,
    fontFamily: 'open-sans-bold',
    marginBottom: 4,
  },
  firstText: {
    color: 'textColorSecondary',
    fontSize: 12,
    fontFamily: 'open-sans-regular',
    marginBottom: 12,
  },
  secondText: {
    color: 'textColorSecondary',
    fontSize: 12,
    fontFamily: 'open-sans-bold',
  },
  rightSide: {
    width: 155,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
  },
  downloadContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadIcon: {
    height: 20,
    width: 20,
  },
  downloadLink: {},
  linkText: {
    textDecorationLine: 'underline',
    color: 'textColorSecondary',
    fontSize: 13,
    fontFamily: 'open-sans-bold',
  },
})
