import React, { FC, useEffect, useRef } from 'react'
import { Text, View } from 'react-native'
import { makeVar, useReactiveVar } from '@apollo/client'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import Dimensions from '../../../constants/Layout'

export enum SnackbarStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const snackbarStateVar = makeVar<ISnackbarStateVar>({
  open: false,
  status: SnackbarStatus.SUCCESS,
  message: '',
})

const Snackbar: FC = () => {
  const timerId = useRef<null | number>(null)
  const data = useReactiveVar(snackbarStateVar)
  const styles = useStyleSheet(themedStyles)

  useEffect(() => {
    if (data.open) {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }

      const id = setTimeout(() => {
        snackbarStateVar({
          ...snackbarStateVar(),
          open: false,
        })
      }, 5000)

      timerId.current = +id
    }
  }, [data, timerId])

  if (!data.open) return null

  return (
    <View
      style={[
        styles.container,
        Dimensions.isSmallDevice && styles.smallDevice,
        data.status === SnackbarStatus.SUCCESS ? styles.success : styles.error,
      ]}
    >
      <Text style={styles.text}>{data.message}</Text>
    </View>
  )
}

export default Snackbar

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background',
    position: 'absolute',
    width: 256,
    minHeight: 48,
    top: 24,
    right: 24,
    zIndex: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 4,
    justifyContent: 'center',
  },
  smallDevice: {
    width: '90%',
  },
  success: {
    backgroundColor: 'successSnackBar',
  },
  error: {
    backgroundColor: 'errorSnackBar',
  },
  text: {
    color: 'textColorWhite',
    fontWeight: '500',
  },
})

interface ISnackbarStateVar {
  open: boolean
  status: SnackbarStatus
  message: string
}
