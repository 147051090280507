import * as React from 'react'
import LinkingConfiguration from './LinkingConfiguration'
import { NavigationContainer } from '@react-navigation/native'
import NotFoundScreen from '../features/notFound/NotFoundScreen'
import { RootStackParamList } from '../types'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import OrdersScreen from '../features/ordersScreen/OrdersScreen'
import DashboardScreen from '../features/dashboardScreen'
import SettingsScreen from '../features/settingsScreen/SettingsScreen'
import ProductsScreen from '../features/productsScreen/ProductsScreen'
import { useMe } from '../hooks/useMe'
import { Text } from 'react-native'
import LoginScreen from '../features/login/LoginScreen'
import TipScreen from '../features/tipScreen/TipScreen'
import ActionsScreen from '../features/actionsScreen'
import ImportingScreen from '../features/importingScreen'

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
export const Stack = createNativeStackNavigator<RootStackParamList>()

function RootNavigator() {
  const { data, loading } = useMe()

  if (loading) return <Text>Loading ...</Text>

  return (
    <Stack.Navigator initialRouteName={data?.me ? 'Actions' : 'Login'}>
      {data?.me ? (
        <>
          <Stack.Screen
            name="Actions"
            component={ActionsScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Orders"
            component={OrdersScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Tips"
            component={TipScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Dashboard"
            component={DashboardScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Products"
            component={ProductsScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Importing"
            component={ImportingScreen}
            options={{ headerShown: false }}
          />
        </>
      ) : (
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ headerShown: false }}
        />
      )}
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  )
}

/*
const Layout = () => {
  return <BaseLayout></BaseLayout>
}
*/
