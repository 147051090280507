import { gql, useMutation } from '@apollo/client'
import {
  SetOrderCustomFields,
  SetOrderCustomFieldsVariables,
} from './types/SetOrderCustomFields'
import { ORDER } from './useOrder'

export const SET_ORDER_CUSTOM_FIELDS = gql`
  mutation SetOrderCustomFields($input: UpdateOrderInput!) {
    setOrderCustomFields(input: $input) {
      id
      customFields {
        orderPaymentGateway
        seen
        tableId
        waiterPayment
        waiterCalling
      }
    }
  }
`

export const useSetOrderCustomFields = () =>
  useMutation<SetOrderCustomFields, SetOrderCustomFieldsVariables>(
    SET_ORDER_CUSTOM_FIELDS,
    { refetchQueries: [ORDER] },
  )
