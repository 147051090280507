import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import TipTableRow, { TipItem } from '../../../features/tipScreen/TipTableRow'

const TableBodyStatic = React.memo(({ list }: { list: TipItem[] }) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      {list.map((item) => (
        <TipTableRow key={item.id} item={item}></TipTableRow>
      ))}
    </View>
  )
})

export default TableBodyStatic

const themedStyles = StyleService.create({
  container: {},
})
