import React from 'react'
import { View as DefaultView } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'viewBgColor',
  },
})

export type ViewProps = DefaultView['props']

const BaseView: React.FC<ViewProps> = ({ style, ...otherProps }) => {
  const styles = useStyleSheet(themedStyles)

  return <DefaultView {...otherProps} style={[styles.container, style]} />
}

export default BaseView
