import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Pressable, Text, View } from 'react-native'
import {
  Autocomplete,
  AutocompleteItem,
  Icon,
  Input,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import { useProductSelectorSearch } from '../../hooks/useProductSelectorSearch'
import { IconProps } from '@ui-kitten/components/ui/icon/icon.component'
import { OrderEditContext } from './OrderEditContext'

const AddNewProduct: FC = () => {
  const {
    addingProductMode,
    newProductsList,
    newProduct,
    setNewProduct,
    activeOrder,
  } = useContext(OrderEditContext)
  const styles = useStyleSheet(themedStyles)
  const [filter, setFilter] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [searchBySelector, { data }] = useProductSelectorSearch()

  const disableQuantity = !newProduct

  useEffect(() => {
    searchBySelector({ variables: { term: filter } })
  }, [searchBySelector, filter])

  useEffect(() => {
    if (!newProduct) {
      setFilter('')
    }
  }, [newProduct])

  const setQuantityHandler = (value: string) => {
    const result = Object.assign(newProduct, { quantity: +value })
    if (/^\d*$/.test(value) && newProduct?.productId) {
      setNewProduct(result)
      setQuantity(+value)
    }
  }

  const onSelect = (index: number) => {
    const product = list[index]

    setNewProduct({
      productId: +product.productVariantId,
      name: product.productVariantName,
      currencyCode: product.currencyCode,
      quantity: 0,
      price:
        product.priceWithTax.__typename === 'SinglePrice'
          ? product.priceWithTax.value
          : product.priceWithTax.max,
    })

    setFilter(list[index].productVariantName)
  }

  const clearInput = () => {
    setFilter('')
    setNewProduct(null)
  }

  const renderCloseIcon = (props: IconProps) => (
    <Pressable onPress={clearInput}>
      <Icon {...props} name="close" />
    </Pressable>
  )

  if (!addingProductMode) return null

  const list = data.filter(({ productVariantId }) => {
    const noFoundInLines = !activeOrder.lines.find(
      ({ productVariant }: any) => productVariantId === productVariant.id,
    )
    const noFoundInNewProducts = !newProductsList.find(
      ({ productId }) => +productVariantId === productId,
    )
    return noFoundInLines && noFoundInNewProducts
  })

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Name or SKU</Text>
      <Autocomplete
        placeholder="Search by product name or SKU"
        value={filter}
        onChangeText={setFilter}
        onSelect={onSelect}
        accessoryRight={renderCloseIcon}
      >
        {list.map(({ productVariantName, productVariantId }) => (
          <AutocompleteItem key={productVariantId} title={productVariantName} />
        ))}
      </Autocomplete>

      <Text style={styles.text}>Quantity</Text>
      <Input
        disabled={disableQuantity}
        placeholder="Quantity"
        value={quantity?.toString() || ''}
        onChangeText={setQuantityHandler}
      />
    </View>
  )
}

export default AddNewProduct

const themedStyles = StyleService.create({
  container: {
    minWidth: 300,
    marginBottom: 16,
  },
  text: {
    fontWeight: '500',
    marginVertical: 10,
    fontSize: 15,
  },
})
