import { timeFormatter } from '../../../../utils/timeFormatter'
import { DatePointValue } from './DatePickerView'
import add from 'date-fns/add'

const isNumber = (value: string) => {
  return !!+value
}

export const isValidDays = (value: string) => {
  return !!+value && value.length <= 2 && +value <= 31
}

export const isValidMonth = (value) => {
  return !!+value && value.length <= 2 && +value <= 12
}

export const isValidYear = (value) => {
  return !!+value && value.length <= 4
}

export const formatDateForString = (date: Date) => {
  const formated = timeFormatter(date)

  const calendarData = `${formated.days}. ${formated.month} ${formated.years}`
  return `${calendarData} - 05:00`
}

export const formatDateRange = (data: DatePointValue): DatePointValue => {
  const { startDate, endDate } = data
  const endTomorrow = add(endDate, { days: 1 })
  endTomorrow.setHours(5)
  endTomorrow.setMinutes(0)

  const startChanges = startDate
  startChanges.setHours(5)
  startChanges.setMinutes(0)

  return { startDate: startChanges, endDate: endTomorrow }
}
