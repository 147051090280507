import { Text, View } from 'react-native'
import withLayout from '../../ui/layout/hoc/withLayout'
import React, { useCallback, useState } from 'react'
import * as DocumentPicker from 'expo-document-picker'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import { useMe } from '../../hooks/useMe'
import Button from '../../ui/primitive/components/Button'
import DownloadTicket from './DownloadTicket'
import Loader, { StatusCodes } from './Loader'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../ui/primitive/components/Snackbar'
import ExampleTicket from './ExampleTicket'
import { IMPORT } from '../../hooks/useImport'
import { createUploadLink } from 'apollo-upload-client'
import Constants from 'expo-constants'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

const ImportingScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const [file, setFile] = useState(new Blob())
  const [fileName, setFileName] = useState('')
  const { data } = useMe()
  const [loaderStatus, setLoaderStatus] = useState<StatusCodes>(
    StatusCodes.WAIT,
  )

  const onPush = useCallback(async () => {
    try {
      setLoaderStatus(StatusCodes.WAIT)
      const channel = data?.me?.channels[0]
      const upLink = createUploadLink({
        uri: `${Constants.manifest?.extra?.API_URL}/admin-api?vendure-token=${channel?.token}`,
        credentials: 'include',
      })
      const client = new ApolloClient({
        link: upLink as unknown as ApolloLink,
        credentials: 'include',
        cache: new InMemoryCache(),
      })

      client
        .mutate({ mutation: IMPORT, variables: { file: file } })
        .then((response) => {
          setLoaderStatus(StatusCodes.IMPORTING)
          const resp: { message: string; status: string } =
            response.data.importCollection
          if (resp.status === 'DONE') {
            setLoaderStatus(StatusCodes.DONE)
          } else {
            snackbarStateVar({
              open: true,
              status: SnackbarStatus.ERROR,
              message: resp.message,
            })
          }
        })
    } catch (e) {
      console.log(e)
    }
  }, [file])

  const onPress = useCallback(() => {
    DocumentPicker.getDocumentAsync()
      .then(async (file) => {
        if (file?.file?.type === 'text/csv') {
          setFile(file?.file)
          setFileName(file.name)
          setLoaderStatus(StatusCodes.WAIT)
        } else {
          setFileName('')
          snackbarStateVar({
            open: true,
            status: SnackbarStatus.ERROR,
            message: 'This file is not CSV',
          })
        }
      })
      .catch((error) => {
        snackbarStateVar({
          open: true,
          status: SnackbarStatus.ERROR,
          message: 'Bad file',
        })
        setFileName('')
      })
  }, [setFile, setFileName])

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerText}>Import Product Catalog</Text>
        <Text style={styles.subHeaderText}>
          Bulk import of your product catalog
        </Text>
      </View>
      {fileName && <Loader state={loaderStatus} />}
      <View style={styles.contentContainer}>
        <View style={styles.fileTypeLine}>
          <Text style={styles.fileTypeFirstText}>Select file to upload</Text>
          <Text style={styles.fileTypeSecondText}>file type: csv</Text>
        </View>
        <View style={styles.fileInputContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.name}>
              {fileName ? fileName : 'No file chosen'}
            </Text>
            <Icon style={styles.icon} name="download" fill="#8f9bb3" />
          </View>
          <View style={styles.buttonContainer}>
            <Button style={styles.chooseButton} type={'blue'} onPress={onPress}>
              <Text style={styles.buttonText}>CHOOSE FILE</Text>
            </Button>
          </View>
        </View>
        <View style={styles.inputDescription}>
          <Icon style={styles.starIcon} name="star" fill="#8f9bb3" />
          <Text style={styles.descriptionText}>
            upload in comma-separated (,) csv format
          </Text>
        </View>
      </View>
      {fileName && (
        <View style={styles.uploadContainer}>
          <Button style={styles.uploadButton} onPress={onPush} type={'primary'}>
            <Text style={styles.buttonText}>UPLOAD NOW</Text>
          </Button>
        </View>
      )}
      <ExampleTicket />
      <DownloadTicket />
    </View>
  )
}

export default withLayout(ImportingScreen)

const themedStyles = StyleService.create({
  header: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'open-sans-bold',
  },
  subHeaderText: {
    fontSize: 15,
    fontFamily: 'open-sans-regular',
  },
  contentContainer: {
    flexDirection: 'column',
    width: '60%',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  fileTypeLine: {
    flexDirection: 'row',
    width: 295,
    justifyContent: 'space-between',
  },
  fileTypeFirstText: {
    textTransform: 'uppercase',
    color: 'textColorSecondary',
    fontSize: 12,
    fontFamily: 'open-sans-regular',
  },
  fileTypeSecondText: {
    color: 'textColorSecondary',
    fontSize: 12,
    fontFamily: 'open-sans-regular',
  },
  fileInputContainer: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'space-evenly',
  },
  icon: {
    width: 24,
    height: 24,
  },
  starIcon: {
    width: 13,
    height: 13,
  },
  buttonContainer: {
    width: 126,
    height: 40,
    justifyContent: 'center',
  },
  chooseButton: {
    minHeight: 40,
    borderRadius: 5,
  },
  buttonText: {
    color: 'textColorWhite',
    fontSize: 14,
    fontFamily: 'open-sans-bold',
  },
  nameContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    border: '1px solid #E4E9F2',
    width: 295,
    borderRadius: 4,
    height: 40,
    backgroundColor: '#F7F9FC',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginRight: 16,
  },
  name: {
    fontSize: 15,
    fontFamily: 'open-sans-regular',
    color: 'textColorSecondary',
  },
  inputDescription: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  descriptionText: {
    marginLeft: 8,
    fontSize: 12,
    fontFamily: 'open-sans-regular',
    color: 'textColorSecondary',
  },
  uploadContainer: {
    width: 135,
    marginTop: 17,
  },
  uploadButton: {
    minHeight: 40,
    borderRadius: 5,
    backgroundColor: '#FFC94D',
    borderColor: '#FFC94D',
  },
})
