import { useQuery, gql } from '@apollo/client'
import { GetMe } from './types/GetMe'

export const GET_ME = gql`
  query GetMe {
    me {
      id
      identifier
      channels {
        id
        code
        permissions
        token
      }
    }
  }
`

export const useMe = () => useQuery<GetMe>(GET_ME)
