import React, { FC } from 'react'
import { Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { Status } from '../../primitive/components/OrderStatus'

const TableBodyItemStatus: FC<ITableBodyItemStatus> = ({ status }) => {
  const styles = useStyleSheet(themedStyles)

  const statusData = {
    style: styles.statusAccepted,
    text: 'Preparing',
  }

  switch (status) {
    case Status.ADDING_ITEMS:
      statusData.style = styles.statusNew
      statusData.text = 'No order'
      break
    case Status.ORDER_PLACED:
      statusData.style = styles.statusNew
      statusData.text = 'New'
      break
    case Status.FULFILLMENT:
      statusData.style = styles.statusAccepted
      statusData.text = 'Accepted'
      break
    case Status.ARRANGING_PAYMENT:
      statusData.style = styles.statusAccepted
      statusData.text = 'Accepted'
      break
    case Status.PAYMENT_AUTHORIZED:
      statusData.style = styles.statusAccepted
      statusData.text = 'Accepted'
      break
    case Status.PAYMENT_SETTLED:
      statusData.style = styles.statusNoActive
      statusData.text = 'Payed'
      break
    case Status.CUSTOM_MODIFYING:
    case Status.MODIFYING:
      statusData.style = styles.statusAccepted
      statusData.text = 'Modify'
      break
    case Status.ARRANGING_ADDITIONAL_PAYMENT:
      statusData.style = styles.statusAccepted
      statusData.text = 'Additional payment'
      break
    case Status.DELIVERED:
      statusData.style = styles.statusNoActive
      statusData.text = 'Delivered'
      break
    case Status.CANCELLED:
      statusData.style = styles.statusNoActive
      statusData.text = 'Cancelled'
      break
    case Status.PAY_LATER:
      statusData.style = styles.statusNew
      statusData.text = 'Ordered (Pay later)'
      break
    case Status.REFUNDED:
      statusData.style = styles.statusNoActive
      statusData.text = 'Refunded'
      break
    case Status.WAITING_WAITERS:
      statusData.style = styles.statusAccepted
      statusData.text = 'Waiting'
      break
  }

  return (
    <View style={styles.container}>
      <View style={[styles.statusEllipse, statusData.style]} />
      <Text style={styles.status}>{statusData.text}</Text>
    </View>
  )
}

export default TableBodyItemStatus

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    fontSize: 12,
    fontWeight: '700',
  },
  statusEllipse: {
    width: 9,
    height: 9,
    borderRadius: 9,
    marginRight: 9,
  },
  statusNew: {
    backgroundColor: 'green',
  },
  statusNoActive: {
    backgroundColor: '#8F9BB3',
  },
  statusAccepted: {
    backgroundColor: '#0095FF',
  },
})

interface ITableBodyItemStatus {
  status: Status
}
