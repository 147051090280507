import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Modal,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import { Text, View } from 'react-native'
import CloseButton from '../../ui/primitive/components/CloseButton'
import { getLongTimeString } from '../../utils/timeFormatter'
import useOpenOrdersByTable from '../../hooks/useOpenOrdersByTable'
import OrderItemInModal from './OrderItemInModal'

const CallingModal = ({ action, onClose }) => {
  const styles = useStyleSheet(themedStyles)
  const [date, setDate] = useState(new Date())
  const data = useOpenOrdersByTable(action.tableId)

  useEffect(() => {
    if (action) {
      setDate(new Date(+action.timeStamp))
    }
  }, [action])

  if (!action || !data) {
    return null
  }

  return (
    <Modal
      visible={true}
      backdropStyle={styles.backdrop}
      onBackdropPress={onClose}
    >
      <Card style={styles.card} disabled={true}>
        <View style={styles.header}>
          <Text style={styles.headerText}>
            Guest at Table {action?.tableId} calls
          </Text>
          <CloseButton onPress={onClose}></CloseButton>
        </View>
        <Text style={styles.subtitle}>{getLongTimeString(date)}</Text>
        <View style={styles.ordersContainer}>
          <View style={styles.containerTitle}>
            {data.length ? (
              <Text style={styles.containerText}>
                info: open orders on table {action?.tableId}
              </Text>
            ) : (
              <Text style={styles.containerText}>
                info: table {action?.tableId} has no active orders
              </Text>
            )}
          </View>
          {!!data.length && (
            <View>
              {data.map((item) => (
                <OrderItemInModal key={item.id} order={item}></OrderItemInModal>
              ))}
            </View>
          )}
        </View>
        <Button
          style={styles.button}
          status="danger"
          appearance="outline"
          onPress={onClose}
        >
          <Text style={styles.buttonText}>DISMISS</Text>
        </Button>
      </Card>
    </Modal>
  )
}

export default CallingModal

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  card: {
    width: 878,
    paddingHorizontal: 16,
    paddingVertical: 16,
    backgroundColor: '#fff',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'open-sans-semibold',
    fontSize: 26,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 15,
    color: '#222B45',
  },
  ordersContainer: {
    marginTop: 16,
  },
  containerTitle: {
    width: '100%',
    backgroundColor: '#F4F4F4',
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginBottom: 16,
  },
  containerText: {
    textTransform: 'uppercase',
    fontFamily: 'open-sans-semibold',
    fontSize: 12,
    color: '#222B45',
  },
  button: {
    width: 80,
  },
  buttonText: {
    textTransform: 'uppercase',
    fontFamily: 'open-sans-semibold',
    fontSize: 12,
    color: '#FF3D71',
  },
})
