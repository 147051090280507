import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { Button, StyleService, useStyleSheet } from '@ui-kitten/components'
import ActionCard, {Action} from './ActionCard'
import { modalStateVar } from '../../ui/primitive/components/Modal'
import OrderModalContainer from '../ordersScreen/OrderModalContainer'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../ui/primitive/components/Snackbar'
import { Audio } from 'expo-av'
import useActions from '../../hooks/useActions'
import { useSetAction } from '../../hooks/useSetAction'
import CallingModal from './CallingModal'
import useOrders from '../../hooks/useOrders'

const ActionsList = () => {
  const [time, setTime] = useState('')
  const { data: actions, loading } = useActions()
  const styles = useStyleSheet(themedStyles)
  // const [comSound, setSound] = useState()
  const [firstVisit, setFirstVisit] = useState(true)
  // const buttonRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [activeActionModal, setActiveActionModal] = useState(null)
  const [setAction] = useSetAction()
  const [intervalTime, setIntervalTime] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalTime(Date.now())
    }, 20000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // useEffect(() => {
  //   if (!firstVisit && actions.length > 0) {
  //     buttonRef.current.onPressIn()
  //   }
  // }, [actions])

  useEffect(() => {
    const load = async () => {
      try {
        const resp = await fetch(
          process.env.API_URL + '/custom-channels/get-time',
        )
        const parsed = await resp.json()
        new Date(parsed.time).toUTCString()
        setTime(new Date(parsed.time).toUTCString())
      } catch (e) {}
    }

    load()
  }, [])

  useEffect(() => {
    if (!loading) {
      visitHandler()
    }
  }, [loading])

  // useEffect(() => {
  //   return comSound
  //     ? () => {
  //         comSound.unloadAsync()
  //       }
  //     : undefined
  // }, [comSound])

  // async function playSound() {
  //   const { sound } = await Audio.Sound.createAsync(
  //     require('../../assets/audio/notif.wav'),
  //   )
  //   setSound(sound)
  //
  //   return sound.playAsync()
  // }

  const visitHandler = () => {
    if (firstVisit) {
      setTimeout(() => {
        setFirstVisit(false)
      }, 2000)
    }
  }

  const openOrderModal = async (id: string) => {
    setTimeout(async () => {
      try {
        await new Promise<void>((resolve) => {
          modalStateVar({
            open: true,
            onSubmit: () => {
              resolve()
            },
            children: ({ closeHandler }) => (
              <OrderModalContainer order={{ id }} closeHandler={closeHandler} />
            ),
          })
        })
      } catch (e) {
        snackbarStateVar({
          open: true,
          status: SnackbarStatus.ERROR,
          message: e.message,
        })
      }
    }, 500)
  }

  const openActionModal = (id: number) => {
    setActiveActionModal(actions.find((item: { id: number }) => item.id === id))
    setVisible(true)
  }

  const closeActionModal = () => {
    setAction({ variables: { id: +activeActionModal?.id } })
    setVisible(false)
  }

  return (
    <>
      {visible && (
        <CallingModal
          action={activeActionModal}
          onClose={closeActionModal}></CallingModal>
      )}
      {time && (
        <View style={styles.container}>
          {actions.map((item: Action) => (
            <ActionCard
              time={time}
              key={item.id}
              openOrderModal={openOrderModal}
              openActionModal={openActionModal}
              action={item}></ActionCard>
          ))}
        </View>
      )}
      {/*<Button style={{ display: 'none' }} ref={buttonRef} onPressIn={playSound}>*/}
      {/*  test*/}
      {/*</Button>*/}
    </>
  )
}

export default ActionsList

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    marginTop: 9,
    flexWrap: 'wrap',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})
