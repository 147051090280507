import React, { FC } from 'react'
import { Pressable, Text } from 'react-native'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import BaseView from '../../primitive/components/BaseView'
import { useLogout } from '../../../hooks/useLogout'
import { GET_ME } from '../../../hooks/useMe'
import { ACTIVE_ADMINISTRATOR } from '../../../hooks/useActiveAdministrator'

const LogOutNawItem: FC = React.memo(() => {
  const styles = useStyleSheet(themedStyles)
  const [handler] = useLogout()
  const clickHandler = () => {
    handler({ refetchQueries: [GET_ME, ACTIVE_ADMINISTRATOR] })
      .then((data) => {
        if (data.data.logout.success) {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Pressable onPress={clickHandler}>
      <BaseView style={[styles.container]}>
        <Icon style={styles.icon} name="log-out-outline" fill="#231F20" />
        <Text style={[styles.text]}>Log Out</Text>
      </BaseView>
    </Pressable>
  )
})

export default LogOutNawItem

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
  },
  text: {
    marginLeft: 6,
    fontSize: 15,
    marginTop: 1,
    color: 'textColorPrimary',
  },
  icon: {
    width: 24,
    height: 24,
  },
})
