import { gql, useQuery } from '@apollo/client'
import { ActiveAdministrator } from './types/ActiveAdministrator'

export const ACTIVE_ADMINISTRATOR = gql`
  query ActiveAdministrator {
    activeAdministrator {
      id
      firstName
      lastName
      emailAddress
    }
  }
`

export const useActiveAdministrator = () => {
  const data = useQuery<ActiveAdministrator>(ACTIVE_ADMINISTRATOR)

  return { ...data, data: data.data?.activeAdministrator }
}
