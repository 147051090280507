import { Text, View } from 'react-native'
import priceTransform from '../../utils/priceTransform'
import React, { FC, useMemo } from 'react'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { dateAgoTransform } from '../../utils/dateAgoTransform'

const TipTableRow: FC<ITipRow> = ({ item }) => {
  const styles = useStyleSheet(themedStyles)

  const total = useMemo(
    () =>
      item.orders.reduce((totalSum: number, order: TipOrder) => {
        return totalSum + order.subTotalWithTax
      }, 0),
    [item],
  )

  return (
    <View key={item.id} style={styles.row}>
      <View style={[styles.col, styles.first, styles.tableId]}>
        <Text>{item.orders[0]?.customFields?.tableId || 1}</Text>
      </View>
      <View style={[styles.col]}>
        <Text>{priceTransform(item.value)} CHF</Text>
      </View>
      <View style={[styles.col]}>
        {item.orders.map((order: TipOrder) => {
          return (
            <Text key={order.id}>
              Order id {order.id} - Total:{' '}
              {priceTransform(order.subTotalWithTax)} CHF
            </Text>
          )
        })}
      </View>
      <View style={[styles.col]}>
        <Text>{priceTransform(total)} CHF</Text>
      </View>
      <View style={[styles.col]}>
        <Text>{priceTransform(total + item.value)} CHF</Text>
      </View>
      <View style={[styles.col]}>
        <Text>{dateAgoTransform(item.createdAt)}</Text>
      </View>
    </View>
  )
}

export default TipTableRow

const themedStyles = StyleService.create({
  first: {
    paddingLeft: 24,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    flex: 1,
    paddingVertical: 18,
    maxWidth: 250,
  },
  tableId: {
    minWidth: 144,
    fontSize: 16,
    fontWeight: '700',
  },
})

export interface TipOrder {
  customFields: {
    tableId: string
  }
  id: string
  subTotalWithTax: number
}
export interface TipItem {
  orders: TipOrder[]
  id: string
  value: number
  createdAt: Date
}

interface ITipRow {
  item: TipItem
}
