import React, { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import DashboardCard from './DashboardCard'
import { useCardsData } from '../../hooks/useCardsData'
import { DashboardContext } from './DashboardContext'
import priceTransform from '../../utils/priceTransform'

const DashboardCardsList = () => {
  const styles = useStyleSheet(themedStyles)
  const { dateRange } = useContext(DashboardContext)
  const data = useCardsData(dateRange)

  const formattedData = useMemo(() => {
    return {
      total: priceTransform(data.total),
      totalByCards: priceTransform(data.totalByCards) + ' CHF',
      totalByCash: priceTransform(data.totalByCash) + ' CHF',
      totalTips: priceTransform(data.totalTips) + ' CHF',
      totalOrders: data.totalOrders,
    }
  }, [data])

  return (
    <View style={styles.container}>
      <DashboardCard
        subContent={'without tips incl. VAT'}
        title={'Total Revenue'}
        content={formattedData.total}
      />
      <DashboardCard
        subContent={'without tips incl. VAT'}
        title={'Payments Cards'}
        content={formattedData.totalByCards}
      />
      <DashboardCard
        subContent={'without tips incl. VAT'}
        title={'Payments Cash'}
        content={formattedData.totalByCash}
      />
      <DashboardCard title={'Total tips'} content={formattedData.totalTips} />
      <DashboardCard
        title={'Total orders'}
        content={formattedData.totalOrders}
      />
    </View>
  )
}

export default DashboardCardsList

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    width: '100%',
    minHeight: 373,
    paddingTop: 36,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
})
