import { gql, useMutation } from '@apollo/client'
import { GET_ME } from './useMe'
import { ACTIVE_ADMINISTRATOR } from './useActiveAdministrator'
import { USE_ACTIVE_CHANNEL } from './useActiveChannel'

const LOGOUT = gql`
  mutation logout {
    logout {
      success
    }
  }
`

export const useLogout = () =>
  useMutation(LOGOUT, {
    refetchQueries: [GET_ME, ACTIVE_ADMINISTRATOR, USE_ACTIVE_CHANNEL],
  })
