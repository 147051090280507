import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Text, View } from 'react-native'
import Switch from '../../ui/primitive/components/Switch'
import { StyleService } from '@ui-kitten/components'
import useCloseOrder from '../../hooks/useCloseOrder'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../ui/primitive/components/Snackbar'
import { modalStateVar } from '../../ui/primitive/components/Modal'
import useAdaptiveStyles from '../../hooks/useAdaptiveStyles'
import { OrderTransform } from '../../utils/orderTransform'
import { Status } from '../../ui/primitive/components/OrderStatus'
import { useAdvanceTransitionOrderToState } from '../../hooks/useTransitionOrderToState'
import { GET_ORDERS } from '../../hooks/useOrders'
import { useRefundOrder } from '../../hooks/useRefundOrder'
import { useRefundSettled } from '../../hooks/useRefundSettled'
import ModalActionButton from '../../ui/primitive/components/ModalActionButton'
import { useSetOrderCustomFields } from '../../hooks/useSetOrderCustomFields'
import { useSetVisitedAction } from '../../hooks/useSetVisitedAction'
import { useAddCahsPayment } from '../../hooks/useAddCahsPayment'
import useOrder from '../../hooks/useOrder'

const OrderModalActions: FC<IOrderModalActions> = ({ order }) => {
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)
  const [closeOrder, { loading: loadingCloseOrder }] = useCloseOrder()
  const [addCashHandler, { loading: loadingAddPaymentToOrder }] =
    useAddCahsPayment()
  const [forcedPaymentSate, setForcedPaymentState] = useState(false)
  const [advanceTransitionOrderToState, { loading }] =
    useAdvanceTransitionOrderToState()
  const [refundOrder, { loading: loadingRefundOrder }] = useRefundOrder()
  const [refundSettled, { loading: loadingRefundSettled }] = useRefundSettled()
  const [refunded, setRefunded] = useState(false)
  const [setOrderCustomFields] = useSetOrderCustomFields()
  const [setVisitedAction] = useSetVisitedAction()

  // const acceptedOrder =
  //   order.status === Status.FULFILLMENT ||
  //   order.status === Status.ARRANGING_PAYMENT ||
  //   order.status === Status.MODIFYING ||
  //   order.status === Status.ARRANGING_ADDITIONAL_PAYMENT ||
  //   order.status === Status.PAYMENT_SETTLED ||
  //   order.status === Status.PAY_LATER ||
  //   order.status === Status.REFUNDED ||
  //   order.status === Status.PAYMENT_AUTHORIZED // TODO advanced examination

  const acceptedOrder = order.customFields.acceptedOrder

  const orderPaid = useMemo(() => {
    return (
      order.state === Status.PAYMENT_AUTHORIZED ||
      order.state === Status.PAYMENT_SETTLED ||
      order.state === Status.REFUNDED
    )
  }, [order])

  useEffect(() => {
    setRefunded(!!order.payments?.some((payment) => !!payment.refunds.length))
  }, [order, setRefunded])

  const acceptOrderWaiter = async () => {
    if (loading) return

    try {
      await setOrderCustomFields({
        variables: {
          input: { id: order.id, customFields: { acceptedOrder: true } },
        },
      })

      await setVisitedAction({
        variables: {
          id: +order.id,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const orderPaidHandler = async () => {
    // TODO ERROR WITH VERSION VENDURE, NEED UPDATE VENDURE FOR FIX: ADD PAYMENT IN ArrangingPayment STATE
    if (loadingAddPaymentToOrder || loading) return

    try {
      if (orderPaid) {
        await advanceTransitionOrderToState({
          state: Status.ARRANGING_PAYMENT,
          order,
        })
      } else {
        await addCashHandler({ variables: { orderId: order.id.toString() } })
        setForcedPaymentState(true)
      }
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }

  const cancelOrderHandler = useCallback(async () => {
    if (loadingCloseOrder) return

    try {
      const { data, errors } = await closeOrder({
        variables: {
          input: {
            orderId: order.id,
            reason: 'Customer request',
          },
        },
      })

      if (!data) {
        throw new Error(errors?.[0]?.message)
      }

      if (data?.cancelOrder.__typename === 'Order') {
        snackbarStateVar({
          open: true,
          status: SnackbarStatus.SUCCESS,
          message: `Order with id: ${order.id} closed`,
        })
        modalStateVar({ ...modalStateVar(), open: false })
      } else if (data?.cancelOrder.__typename === 'OrderStateTransitionError') {
        throw new Error(data?.cancelOrder.transitionError)
      } else {
        throw new Error(data?.cancelOrder.message)
      }
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }, [loadingCloseOrder, closeOrder, order])

  const refundHandler = useCallback(async () => {
    if (loadingRefundOrder || loadingRefundSettled) return

    const orderHasRefund = !!order.payments?.some(
      (payment) => !!payment.refunds.length,
    )

    const orderNoHasPayment = !order.payments?.length

    try {
      if (orderHasRefund) {
        throw new Error('Order has already received a refund')
      }
      if (orderNoHasPayment) {
        throw new Error('The order was not paid')
      }

      const paymentId = order.payments![0].id

      if (!paymentId) {
        throw new Error('Payment not found')
      }

      const { data } = await refundOrder({
        variables: {
          input: {
            paymentId,
            shipping: 0,
            adjustment: 0, // TODO maybe input
            lines: order.lines.map((line) => ({
              orderLineId: line.id,
              quantity: line.quantity,
            })), // TODO input
          },
        },
      })

      await advanceTransitionOrderToState({
        order,
        state: Status.REFUNDED,
        refetchQueries: [GET_ORDERS],
        awaitRefetchQueries: true,
      })
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.SUCCESS,
        message: `Refund successfully`,
      })
    } catch (e) {
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }, [
    loadingRefundOrder,
    loadingRefundSettled,
    refundOrder,
    refundSettled,
    order,
  ])

  return (
    <View style={styles.container}>
      <View style={styles.switches}>
        <View style={styles.firstSwitch}>
          <Switch
            toggleSwitch={acceptOrderWaiter}
            isEnabled={acceptedOrder}
            iconName="checkmark-outline"
            activeColor="#00D68F"
          />
          <Text style={styles.headerActionsText}>Accepted</Text>
        </View>
        <View>
          <Switch
            toggleSwitch={orderPaidHandler}
            isEnabled={orderPaid || forcedPaymentSate}
            iconName="loader-outline"
            activeColor="#598BFF"
          />
          <Text style={styles.headerActionsText}>Paid</Text>
        </View>
      </View>
      <View style={styles.buttons}>
        <ModalActionButton
          disabled={orderPaid || forcedPaymentSate}
          onPress={cancelOrderHandler}
          style={styles.firstButton}
          text="Cancel Order"
        />
        {orderPaid ||
          (forcedPaymentSate && (
            <>
              {refunded ? (
                <ModalActionButton
                  onPress={refundHandler}
                  text="Refunded"
                  disabled={true}
                />
              ) : (
                <ModalActionButton onPress={refundHandler} text="Refund" />
              )}
            </>
          ))}
      </View>
    </View>
  )
}

export default OrderModalActions

interface IOrderModalActions {
  order: OrderTransform
}

const themedStyles = StyleService.create({
  container: {},
  switches: {
    flexDirection: 'row',
    marginTop: 12,
  },
  headerActionsText: {
    marginTop: 12,
    marginBottom: 20,
    fontWeight: '700',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  firstSwitch: {
    marginRight: 56,
  },
  buttons: {
    flexDirection: 'row',
  },
  firstButton: {
    marginRight: 8,
  },
  text: {
    textTransform: 'uppercase',
    color: 'pink',
    fontWeight: '700',
  },
})

const themedSmStyles = StyleService.create({
  container: {
    flexDirection: 'column-reverse',
    marginTop: 16,
    marginBottom: 32,
  },
  switches: {
    flexDirection: 'row',
    marginTop: 28,
  },
})
