import { gql, useMutation } from '@apollo/client'
import { CHANNEL_FRAGMENT } from './useActiveChannel'
import { UpdateChannel, UpdateChannelVariables } from './types/UpdateChannel'

const UPDATE_CHANNEL = gql`
  mutation UpdateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      ... on ErrorResult {
        errorCode
        message
      }
      ...ChannelFragment
    }
  }
  ${CHANNEL_FRAGMENT}
`
export const useUpdateChannel = () =>
  useMutation<UpdateChannel, UpdateChannelVariables>(UPDATE_CHANNEL)
