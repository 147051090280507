import React from 'react'
import { Text } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const NoProducts = () => {
  const styles = useStyleSheet(themedStyles)

  return (
    <Text style={styles.noActiveProduct}>No products have been added yet</Text>
  )
}

export default NoProducts

const themedStyles = StyleService.create({
  noActiveProduct: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 16,
  },
})
