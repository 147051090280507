import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Text, View } from 'react-native'
import { StyleService } from '@ui-kitten/components'
import priceTransform from '../../utils/priceTransform'
import useAdaptiveStyles from '../../hooks/useAdaptiveStyles'
import { EditModeContext } from './ModalContext'
import { useModifyOrder } from '../../hooks/useModifyOrder'
import { OrderTransform } from '../../utils/orderTransform'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../ui/primitive/components/Snackbar'
import { timeFormatter } from '../../utils/timeFormatter'
import { usePaymentData } from '../../hooks/usePaymentData'

const OrderModalPricing: FC<IOrderModalPricing> = ({ order }) => {
  const [pricingState, setPricingState] = useState({
    totalWithTax: order.totalWithTax,
    tipp: order.tipp,
    total: order.totalWithTax + order.tipp,
    currencyCode: order.currencyCode,
  })
  const [paymentData, setPaymentData] = useState({})
  const [paymentDetailsShow, setPaymentDetailsShow] = useState(false)
  const {
    state: { lines, newProducts, equal },
  } = useContext(EditModeContext)
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)
  const [modifyOrder] = useModifyOrder()
  const [getPayment] = usePaymentData()

  useEffect(() => {
    const load = async () => {
      try {
        const deletedItems = order.lines
          .filter((line) => !lines.find((newLine) => newLine.id === line.id))
          .map((el) => ({ ...el, quantity: 0 }))

        const editItems = lines.filter(
          (newLine) => !!order.lines.find((line) => line.id === newLine.id),
        )

        const adjustOrderLines = [...editItems, ...deletedItems]

        const { data } = await modifyOrder({
          variables: {
            dryRun: true,
            id: order.id,
            optionsModify: {
              recalculateShipping: true,
            },
            adjustOrderLines: adjustOrderLines.map((el) => ({
              orderLineId: el.id,
              quantity: el.quantity,
            })),
            addItems: newProducts.map((el) => ({
              quantity: el.quantity,
              productVariantId: el.id,
            })),
          },
          fetchPolicy: 'no-cache',
        })

        if (!data?.modifyOrder) {
          throw new Error('Failed, please try later')
        }

        if (data.modifyOrder.__typename !== 'Order') {
          throw new Error(data.modifyOrder.message)
        }

        setPricingState({
          totalWithTax: data.modifyOrder.totalWithTax,
          tipp: data.modifyOrder.customFields?.tipp || 0,
          total:
            data.modifyOrder.totalWithTax +
            (data.modifyOrder.customFields?.tipp || 0),
          currencyCode: data.modifyOrder.currencyCode,
        })
      } catch (e) {
        snackbarStateVar({
          open: true,
          status: SnackbarStatus.ERROR,
          message: e.message,
        })
      }
    }
    if (order.customFields.orderPaymentGateway) {
      gettingPaymentData()
    }
    if (equal) {
      setPricingState((prev) => ({
        ...prev,
        totalWithTax: order.totalWithTax,
        tipp: order.tipp,
        total: order.totalWithTax + order.tipp,
      }))
    } else {
      load()
    }
  }, [equal, order, lines, newProducts, modifyOrder])

  const gettingPaymentData = () => {
    const gatewayData = JSON.parse(order.customFields.orderPaymentGateway)
    getPayment({
      variables: {
        gatewayId: gatewayData.id.toString(),
      },
    })
      .then((resp) => {
        const transaction = resp.data.getGatewayDataById
        setPaymentData(
          Object.assign(transaction, { currency: gatewayData.currency }),
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const switchDetails = () => {
    setPaymentDetailsShow(!paymentDetailsShow)
  }

  const formattedDate = (createdAt: number) => {
    const dataObj = timeFormatter(new Date(createdAt))
    return `${dataObj.hours}:${dataObj.minutes} ${dataObj.days} ${dataObj.month} ${dataObj.years}`
  }

  return (
    <View style={styles.container}>
      {!paymentDetailsShow && (
        <>
          <View style={styles.row}>
            <Text style={styles.text}>Subtotal</Text>
            <Text style={styles.text}>
              {`${priceTransform(pricingState.totalWithTax)} ${
                pricingState.currencyCode
              }`}
            </Text>
          </View>
          <View style={[styles.row, styles.tipp]}>
            <Text style={styles.text}>Tip</Text>
            <Text style={styles.text}>
              {`${priceTransform(pricingState.tipp)} ${
                pricingState.currencyCode
              }`}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.text, styles.totalText]}>Total</Text>
            <Text style={[styles.text, styles.totalText]}>
              {`${priceTransform(pricingState.total)} ${
                pricingState.currencyCode
              }`}
            </Text>
          </View>
        </>
      )}
      {paymentDetailsShow && (
        <>
          <View style={styles.row}>
            <Text style={styles.text}>Transaction currency</Text>
            <Text style={styles.text}>{paymentData.currency}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.text}>Transaction id</Text>
            <Text style={styles.text}>{paymentData.id}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.text}>Payed time</Text>
            <Text style={styles.text}>{formattedDate(paymentData.time)}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.text}>Transaction status</Text>
            <Text style={styles.text}>{paymentData.status}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.text}>Amount</Text>
            <Text style={styles.text}>
              {`${priceTransform(paymentData.amount)} ${paymentData.currency}`}
            </Text>
          </View>
        </>
      )}
      {order.customFields.orderPaymentGateway && (
        <View style={styles.buttonContainer}>
          <Button title={'Payment details'} onPress={switchDetails} />
        </View>
      )}
    </View>
  )
}

export default OrderModalPricing

interface IOrderModalPricing {
  order: OrderTransform
}

const themedStyles = StyleService.create({
  container: {
    maxWidth: 335,
    width: '100%',
    padding: 16,
    backgroundColor: 'modalLightGrey',
    borderColor: 'borderColorPrimary',
    borderWidth: 1,
    borderRadius: 4,
    height: 'auto',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  tipp: {
    marginTop: 10,
    marginBottom: 16,
  },
  buttonContainer: {
    marginVertical: 5,
    width: '100%',
    paddingHorizontal: 15,
  },
  text: {
    fontSize: 15,
  },
  totalText: {
    fontWeight: '700',
  },
})

const themedSmStyles = StyleService.create({
  container: {
    maxWidth: 'auto',
    width: '100%',
    padding: 16,
    backgroundColor: 'modalLightGrey',
    borderColor: 'borderColorPrimary',
    borderWidth: 1,
    borderRadius: 4,
    height: 'auto',
  },
})
