import React, { FC } from 'react'
import withLayout from '../../ui/layout/hoc/withLayout'
import OrdersTable from './OrdersTable'
import Header from './Header'
import { OrdersTableContextProvider } from './OrdersTableContext'

const OrdersScreen: FC = () => {
  return (
    <OrdersTableContextProvider>
      <Header />
      <OrdersTable />
    </OrdersTableContextProvider>
  )
}

export default withLayout(OrdersScreen)
