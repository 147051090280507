import React, { FC, useMemo } from 'react'
import OrderModal from './OrderModal'
import { orderTransform } from '../../utils/orderTransform'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../ui/primitive/components/Snackbar'
import useOrder from '../../hooks/useOrder'

const OrderModalContainer: FC<IOrderModalContainer> = React.memo(
  ({ order: inputOrder, closeHandler }) => {
    const { order } = useOrder(inputOrder.id)

    const transformedOrder = useMemo(() => {
      if (order) return orderTransform([order])[0]
    }, [order])

    if (!order) return null

    if (!transformedOrder) {
      snackbarStateVar({
        open: false,
        status: SnackbarStatus.ERROR,
        message: 'Order not found',
      })
      return null
    }

    return <OrderModal order={transformedOrder} closeHandler={closeHandler} />
  },
)

export default OrderModalContainer

interface IOrderModalContainer {
  order: { id: number }
  closeHandler: () => void
}
