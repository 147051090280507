import React, { FC } from 'react'
import { Icon } from '@ui-kitten/components'
import { Switch as DefaultSwitch } from 'react-native-switch'

const Switch: FC<ISwitch> = ({
  isEnabled,
  toggleSwitch,
  activeColor,
  iconName,
}) => {
  return (
    <DefaultSwitch
      value={isEnabled}
      onValueChange={toggleSwitch}
      activeText=""
      inActiveText=""
      circleSize={28}
      barHeight={32}
      circleBorderWidth={0}
      backgroundActive={activeColor}
      backgroundInactive={'#8F9BB328'}
      renderInsideCircle={() => (
        <Icon
          name={iconName}
          style={{
            width: 12,
            height: 12,
            fill: isEnabled ? activeColor : '#ffffff',
          }}
        />
      )}
      switchLeftPx={3}
      switchRightPx={3}
      switchBorderRadius={100}
    />
  )
}

export default Switch

interface ISwitch {
  isEnabled: boolean
  toggleSwitch: () => void
  activeColor: string
  iconName: string // from ui kitten icon
}
