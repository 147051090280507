import { View, Text } from 'react-native'
import React from 'react'
import { getLongTimeString } from '../../utils/timeFormatter'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import priceTransform from '../../utils/priceTransform'

const OrderItemInModal = ({ order }) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.orderNumber}>Nr. {order.id}</Text>
        <Text style={styles.date}>
          {getLongTimeString(new Date(+order.customFields.createTime))} by{' '}
          {order.customFields.customerName}
        </Text>
      </View>
      <View style={styles.total}>
        <Text style={styles.totalText}>{priceTransform(order.total)} CHF</Text>
      </View>
    </View>
  )
}

export default OrderItemInModal

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#F7F9FC',
    borderBottomStyle: 'solid',
  },
  content: {},
  orderNumber: {
    fontSize: 18,
    fontFamily: 'open-sans-semibold',
    color: '#222B45',
  },
  date: {
    fontSize: 13,
    fontFamily: 'open-sans-semibold',
    color: '#2E3A59',
  },
  total: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EDF1F7',
  },
  totalText: {
    fontSize: 13,
    fontFamily: 'open-sans-semibold',
    color: '#222B45',
  },
})
