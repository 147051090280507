import { gql, useMutation } from '@apollo/client'
import { CancelOrder, CancelOrderVariables } from './types/CancelOrder'

const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      ... on Order {
        id
        state
      }
      ... on ErrorResult {
        errorCode
        message
      }
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
      }
    }
  }
`

const useCloseOrder = () =>
  useMutation<CancelOrder, CancelOrderVariables>(CANCEL_ORDER)

export default useCloseOrder
