import React, { FC, useContext } from 'react'
import { Text, View } from 'react-native'
import { StyleService } from '@ui-kitten/components'
import { Orders_orders_items_lines } from '../../../hooks/types/Orders'
import NoProducts from './NoProducts'
import useAdaptiveStyles from '../../../hooks/useAdaptiveStyles'
import { EditModeContext } from '../ModalContext'
import ListItemContainer from './ListItemContainer'
import NewProductContainer from './NewProductContainer'
import { OrderEditContext } from '../OrderEditContext'
import { CurrencyCode } from '../../../types/global-types'

const ProductList: FC<IProductList> = React.memo(({ products }) => {
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)
  const {
    state: { lines, disable },
  } = useContext(EditModeContext)
  const { newProductsList } = useContext(OrderEditContext)
  const list = disable ? products : lines

  return (
    <View>
      {!list.length ? (
        <NoProducts />
      ) : (
        <>
          <View style={[styles.tableRow, styles.tableHead]}>
            <Text style={[styles.tableHeaderText, styles.productTable]}>
              Product
            </Text>
            <Text style={[styles.tableHeaderText, styles.amount]}>Amount</Text>
            <Text style={[styles.tableHeaderText, styles.total]}>Total</Text>
          </View>

          <View style={styles.tableBody}>
            {list.map(
              ({ id, linePriceWithTax, productVariant, quantity }, index) => (
                <ListItemContainer
                  id={productVariant.id}
                  key={id}
                  linePriceWithTax={linePriceWithTax}
                  quantity={quantity}
                  name={productVariant.name}
                  currencyCode={productVariant.currencyCode}
                  priceWithTax={productVariant.priceWithTax}
                  firstItem={!index}
                />
              ),
            )}
            {newProductsList.map(
              ({ productId, quantity, name, price, currencyCode }, index) => (
                <NewProductContainer
                  id={productId.toString()}
                  key={productId + name + quantity + price + index}
                  linePriceWithTax={quantity * (price ?? 1)}
                  quantity={quantity}
                  name={name}
                  currencyCode={currencyCode ?? CurrencyCode.USD}
                  priceWithTax={price ?? 0}
                  firstItem={!index}
                />
              ),
            )}
          </View>
        </>
      )}
    </View>
  )
})

export default ProductList

interface IProductList {
  products: Orders_orders_items_lines[]
}

const themedStyles = StyleService.create({
  tableRow: {
    flexDirection: 'row',
  },
  tableHeaderText: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '700',
  },
  tableHead: {},
  productTable: {
    flexGrow: 1,
  },
  amount: {
    width: 154,
  },
  total: {
    width: 180,
  },
  tableBody: {},
})

const themedSmStyles = StyleService.create({
  tableHead: {
    display: 'none',
  },
})
