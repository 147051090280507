import React, { useCallback, useMemo } from 'react'
import { ScrollView, View, Text, GestureResponderEvent } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { ExampleRows, ExampleText } from './examplesRows'
import { A } from '@expo/html-elements'
import { useMe } from '../../hooks/useMe'

const ExampleTicket = () => {
  const styles = useStyleSheet(themedStyles)
  const { data } = useMe()

  const channelCode = useMemo(
    () => data?.me?.channels[0].code || 'category',
    [data],
  )

  const rows = useMemo(() => ExampleRows(channelCode), [channelCode])

  const download = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const blob = new Blob([ExampleText(channelCode)], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.setAttribute('href', url)
      a.setAttribute('download', 'template.csv')
      a.click()
      a.remove()
    },
    [channelCode],
  )

  return (
    <ScrollView horizontal={true} style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.helpText}>
          - Facet name format: "channel-name":Facet Value. Example: "
          {channelCode}:Coffee".
        </Text>
        <Text style={styles.helpText}>
          - There must be only one facet:FacetValue pair.
        </Text>
        <Text style={styles.helpText}>- Slug and sku must be unique.</Text>
        <Text style={styles.helpText}>
          - "optionGroup" must be one, the number of product options is
          unlimited.
        </Text>
        <A style={styles.link} href={'/'} onPress={download}>
          <Text style={styles.linkText}>Download catalog-template</Text>
        </A>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            {Object.keys(rows[0]).map((name) => (
              <View
                key={name + 'head'}
                style={[
                  styles.tableHeaderCell,
                  styles.tableCell,
                  name === 'facets' && styles.facetCell,
                ]}
              >
                <Text style={styles.helpText}>{name}</Text>
              </View>
            ))}
          </View>
          {rows.map((row) => (
            <View key={row.name + 'row'} style={styles.tableRow}>
              {Object.keys(row).map((name: string, id) => (
                <View
                  key={id}
                  style={[
                    styles.tableCell,
                    name === 'facets' && styles.facetCell,
                  ]}
                >
                  <Text style={styles.helpText}>{row[name]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    </ScrollView>
  )
}

export default ExampleTicket

const themedStyles = StyleService.create({
  container: {
    backgroundColor: '#fff',
    minWidth: 794,
    maxWidth: 900,
    flexDirection: 'row',
    paddingVertical: 18,
    marginTop: 30,
  },
  content: {
    alignItems: 'flex-start',
    paddingTop: 15,
  },
  helpText: {
    fontSize: 12,
    fontFamily: 'open-sans-regular',
  },
  table: {
    marginTop: 15,
  },
  tableHeader: {
    flexDirection: 'row',
  },
  tableHeaderCell: {},
  tableRow: {
    flexDirection: 'row',
    minHeight: 30,
    alignItems: 'stretch',
  },
  tableCell: {
    width: 110,
    paddingHorizontal: 10,
    borderColor: '#111',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  facetCell: {
    width: 150,
  },
  link: {
    marginTop: 5,
  },
  linkText: {
    textDecorationLine: 'underline',
    color: 'textColorSecondary',
    fontSize: 13,
    fontFamily: 'open-sans-bold',
  },
})
