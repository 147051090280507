import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Button, Input } from '@ui-kitten/components'
import { useLogin } from '../../hooks/useLogin'
import { useNavigation } from '@react-navigation/native'
import { GET_ME } from '../../hooks/useMe'

const LoginScreen = () => {
  const navigation = useNavigation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginMutation, { data }] = useLogin()

  const login = async () => {
    const { data } = await loginMutation({
      variables: {
        email,
        password,
      },
      refetchQueries: [GET_ME],
    })

    if (data?.login.__typename === 'CurrentUser') {
      navigation.navigate('Actions')
    }
  }

  return (
    <View style={styles.wrapper}>
      <Input placeholder="Email" value={email} onChangeText={setEmail} />
      <Input
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />

      <Button onPress={login}>login</Button>
      <Text>
        {data?.login?.__typename !== 'CurrentUser' && data?.login.message}
      </Text>
    </View>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    marginBottom: 20,
    padding: 5,
    backgroundColor: '#d7d7d7',
  },
})
