import React, { FC, ReactNode } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  Modal as ModalComponent,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components'
import { View } from 'react-native'

export const modalStateVar = makeVar<ISnackbarStateVar>({
  open: false,
  onSubmit: () => {},
  children: null,
})

const Modal: FC = () => {
  const data = useReactiveVar(modalStateVar)
  const styles = useStyleSheet(themedStyles)

  const submitHandler = () => {
    modalStateVar({
      ...modalStateVar(),
      open: false,
    })

    data.onSubmit(true)
  }

  const closeHandler = () => {
    modalStateVar({
      ...modalStateVar(),
      open: false,
    })

    data.onSubmit(false)
  }

  if (!data.open) return null

  return (
    <ModalComponent
      visible={data.open}
      backdropStyle={styles.backdrop}
      onBackdropPress={closeHandler}
    >
      <View style={styles.container}>
        {typeof data.children === 'function'
          ? data.children({ closeHandler, submitHandler })
          : data.children}
      </View>
    </ModalComponent>
  )
}

export default Modal

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    backgroundColor: 'modalBackgroundColor',
  },
  backdrop: {
    backgroundColor: '#0000007F',
  },
})

interface ISnackbarStateVar {
  open: boolean
  children:
    | (({ closeHandler, submitHandler }: IChildrenProps) => ReactNode)
    | ReactNode
    | null
  onSubmit: (status: boolean) => void
}

interface IChildrenProps {
  closeHandler: () => void
  submitHandler: () => void
}
