import { Pressable, Text, View } from 'react-native'
import priceTransform from '../../../utils/priceTransform'
import { Icon, StyleService } from '@ui-kitten/components'
import React, { FC, useContext, useMemo } from 'react'
import useAdaptiveStyles from '../../../hooks/useAdaptiveStyles'
import { CurrencyCode } from '../../../types/global-types'
import { OrderEditContext } from '../OrderEditContext'

interface IListItem {
  linePriceWithTax: number
  quantity: number
  name: string
  currencyCode: CurrencyCode
  priceWithTax: number
  firstItem: boolean
  deleteHandler: () => void
  changeQuantityHandler: (count: -1 | 1) => () => void
  isNewListItem?: boolean
}

const ListItem: FC<IListItem> = ({
  firstItem,
  currencyCode,
  priceWithTax,
  name,
  quantity,
  linePriceWithTax,
  deleteHandler,
  changeQuantityHandler,
  isNewListItem = false,
}) => {
  const { editMode, changesList } = useContext(OrderEditContext)
  const styles = useAdaptiveStyles(themedStyles, themedSmStyles)

  const getCurChange = useMemo(() => {
    return changesList.find((item) => item.name === name)
  }, [changesList, name])

  const getTotalQuantity = useMemo(() => {
    if (getCurChange) {
      return quantity + getCurChange?.quantity || 0
    } else {
      return quantity
    }
  }, [getCurChange, quantity])

  return (
    <View
      style={[
        styles.tableRow,
        styles.product,
        firstItem && styles.firstProduct,
        (!!getCurChange || isNewListItem) && styles.changesStyle,
      ]}
    >
      <View style={styles.productTable}>
        <Text style={styles.productName}>{name}</Text>
        <Text style={styles.price}>
          {`${priceTransform(priceWithTax)} ${currencyCode}`}
        </Text>
      </View>

      <View style={styles.productActions}>
        <View style={[styles.amount, styles.tableBodyAmount]}>
          <Pressable
            style={[
              styles.countButton,
              (!editMode || getTotalQuantity < 1) && styles.disable,
            ]}
            disabled={!editMode || getTotalQuantity < 1}
            onPress={changeQuantityHandler(-1)}
          >
            <Icon name="minus-outline" style={styles.countIcon} />
          </Pressable>
          <View style={styles.count}>
            <Text>{getTotalQuantity}</Text>
          </View>
          <Pressable
            style={[styles.countButton, !editMode && styles.disable]}
            disabled={!editMode}
            onPress={changeQuantityHandler(1)}
          >
            <Icon name="plus-outline" style={styles.countIcon} />
          </Pressable>
        </View>

        <View style={[styles.total, styles.totalTable]}>
          <View style={styles.totalLinePrice}>
            <Text>{`${priceTransform(linePriceWithTax)} ${currencyCode}`}</Text>
          </View>
          <Pressable
            style={[
              styles.countButton,
              styles.delete,
              !editMode && styles.disable,
            ]}
            disabled={!editMode}
            onPress={deleteHandler}
          >
            <Icon
              fill="#FF3D71"
              name="trash-2-outline"
              style={styles.countIcon}
            />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

export default ListItem

const themedStyles = StyleService.create({
  tableRow: {
    flexDirection: 'row',
    paddingHorizontal: 5,
  },
  productTable: {
    flexGrow: 1,
  },
  product: {
    borderColor: 'borderColorPrimary',
    borderTopWidth: 1,
    paddingVertical: 16,
  },
  firstProduct: {
    borderTopWidth: 0,
  },
  amount: {
    width: 154,
  },
  total: {
    width: 180,
  },
  totalTable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalLinePrice: {
    borderColor: 'borderColorPrimary',
    borderWidth: 1,
    maxWidth: 120,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productActions: {
    flexDirection: 'row',
  },
  productName: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 4,
    color: 'textColorPrimary',
  },
  price: {
    color: 'textColorTableBody',
  },
  countButton: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'modalLightGrey',
  },
  count: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
  },
  tableBodyAmount: {
    flexDirection: 'row',
  },
  countIcon: {
    width: 20,
    height: 20,
  },
  delete: {
    borderRadius: 4,
    backgroundColor: 'lightPink',
  },
  disable: {
    opacity: 0.6,
  },
  changesStyle: {
    backgroundColor: '#ebe6b2',
  },
})

const themedSmStyles = StyleService.create({
  product: {
    flexDirection: 'column',
    borderColor: 'borderColorPrimary',
    borderTopWidth: 1,
    paddingVertical: 16,
  },
  productActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
})
