import { ActiveOrders_orders_items_payments } from '../hooks/types/ActiveOrders'

export const isPainOrder = (
  total: number,
  payments: ActiveOrders_orders_items_payments[] | null,
): boolean => {
  if (!payments) return false

  const amount = payments.reduce((sum, { amount }) => sum + amount, 0)

  return amount >= total && amount !== 0
}
