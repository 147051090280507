import React, { FC } from 'react'
import { View } from 'react-native'
import withLayout from '../../ui/layout/hoc/withLayout'
import DashboardHeader from './DashboardHeader'
import { DashboardContextProvider } from './DashboardContext'
import DashboardCardsList from './DashboardCardsList'
import DashboardTable from './DashboardTable'

const DashboardScreen: FC = () => {
  return (
    <View>
      <DashboardContextProvider>
        <DashboardHeader></DashboardHeader>
        <DashboardCardsList></DashboardCardsList>
        <DashboardTable></DashboardTable>
      </DashboardContextProvider>
    </View>
  )
}

export default withLayout(DashboardScreen)
