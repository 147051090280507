import { gql, useQuery } from '@apollo/client'
import { DatePointValue } from '../ui/primitive/components/DatePicker/DatePickerView'

interface CardsData {
  total: number
  totalByCards: number
  totalByCash: number
  totalTips: number
  totalOrders: number
}
export const GET_CARDS_DATA = gql`
  query getCardsData($dateRange: DateRange) {
    getCardsData(dateRange: $dateRange) {
      total
      totalByCards
      totalByCash
      totalTips
      totalOrders
    }
  }
`

export const useCardsData = (timeRange: DatePointValue) => {
  const { endDate, startDate } = timeRange
  const data = useQuery(GET_CARDS_DATA, {
    variables: { dateRange: { start: startDate, end: endDate } },
  })

  if (data?.data?.getCardsData) {
    return <CardsData>data.data.getCardsData
  } else {
    return {
      total: 0,
      totalByCards: 0,
      totalByCash: 0,
      totalTips: 0,
      totalOrders: 0,
    }
  }
}
