import { gql, useMutation } from '@apollo/client'

export const IMPORT = gql`
  mutation importCollection($file: Upload!) {
    importCollection(file: $file) {
      message
      status
    }
  }
`

export const useImport = () => useMutation(IMPORT)
