import React, { FC, useCallback, useMemo, useState } from 'react'
import { formatDateRange } from '../../ui/primitive/components/DatePicker/helpers'
import { Range } from '../dashboardScreen/DashboardContext'

export const OrdersContext = React.createContext({
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
  setDateRange: (range: Range) => {},
})

export interface IOrderTableContextProvider {
  dateRange: Range
  setDateRange: (range: Range) => void
}

export const OrdersTableContextProvider: FC<IOrderTableContextProvider> = ({
  children,
}) => {
  const defaultData = useMemo(() => {
    const today = new Date()
    return formatDateRange({ startDate: today, endDate: today })
  }, [])
  const [dateRange, setRange] = useState(defaultData)

  const setDateRange = useCallback(
    (range: Range) => {
      setRange(range)
    },
    [setRange],
  )

  const value = useMemo(
    () => ({
      dateRange,
      setDateRange,
    }),
    [dateRange, setRange],
  )

  return (
    <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
  )
}
