import { gql, useMutation } from '@apollo/client'
import { ORDER } from './useOrder'

const ADD_CASH_TO_ORDER = gql`
  mutation addCahsPayment($orderId: String!) {
    addCahsPayment(orderId: $orderId) {
      id
    }
  }
`

export const useAddCahsPayment = () => {
  return useMutation(ADD_CASH_TO_ORDER, { refetchQueries: [] })
}
