import React, { FC } from 'react'
import { Pressable, Text, View } from 'react-native'
import priceTransform from '../../utils/priceTransform'
import TableBodyItemStatus from '../../ui/layout/table/TableBodyItemStatus'
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components'
import { isPainOrder } from '../../utils/isPainOrder'
import { dateAgoTransform } from '../../utils/dateAgoTransform'
import { OrderTransform } from '../../utils/orderTransform'

const Order: FC<IOrder> = ({ data, openOrderModal }) => {
  const {
    tableId,
    seen,
    id,
    customerName,
    createdAt,
    totalWithTax,
    currencyCode,
    payments,
    status,
  } = data
  const styles = useStyleSheet(themedStyles)
  return (
    <Pressable
      style={[styles.item, !seen && styles.new]}
      key={id}
      onPress={() => openOrderModal(id)}
    >
      <View style={[styles.tableColum, styles.first, styles.tableId]}>
        <Text style={[styles.tableIdText]}>{tableId}</Text>
      </View>

      <View style={styles.tableColum}>
        <Text style={styles.text}>#{id}</Text>
      </View>
      <Text style={[styles.tableColum, styles.text]}>{customerName}</Text>
      <View style={styles.tableColum}>
        <Text style={styles.text}>{dateAgoTransform(createdAt)}</Text>
      </View>
      <View style={styles.tableColum}>
        <View
          style={[
            styles.priceContainer,
            isPainOrder(totalWithTax, payments)
              ? styles.paidContainer
              : styles.unPaidContainer,
          ]}
        >
          <Text
            style={[
              styles.price,
              isPainOrder(totalWithTax, payments)
                ? styles.pricePaid
                : styles.priceUnPaid,
            ]}
          >
            {`${currencyCode} ${priceTransform(totalWithTax)}`}
          </Text>
        </View>
      </View>
      <View style={styles.tableColum}>
        <TableBodyItemStatus status={status} />
      </View>
      <View style={styles.last}>
        <Icon name="more-vertical-outline" style={styles.icon} />
      </View>
    </Pressable>
  )
}

export default Order

interface IOrder {
  data: OrderTransform
  openOrderModal: (id: string) => void
}

const themedStyles = StyleService.create({
  container: {},
  last: {
    justifyContent: 'center',
  },
  first: {
    paddingLeft: 24,
  },
  item: {
    paddingRight: 16,
    flexDirection: 'row',
    paddingVertical: 28,
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  new: {
    backgroundColor: 'greyBackground',
  },
  tableId: {
    minWidth: 144,
  },
  tableIdText: {
    fontSize: 16,
    fontWeight: '700',
  },
  tableColum: {
    width: '16%',
    minWidth: 120,
    justifyContent: 'center',
  },
  text: {
    fontSize: 13,
    color: 'textColorTableBody',
  },
  priceContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
  },
  paidContainer: {
    backgroundColor: '#F0FFF5',
  },
  unPaidContainer: {
    backgroundColor: '#FFF2F2',
  },
  price: {
    fontSize: 13,
    fontWeight: '700',
  },
  priceUnPaid: {
    color: 'pink',
  },
  pricePaid: {
    color: 'green',
  },
  icon: {
    width: 24,
    height: 24,
  },
})
