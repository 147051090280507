import React, { FC, useContext, useState } from 'react'
import { Text, useWindowDimensions, View } from 'react-native'

import { StyleService, useStyleSheet } from '@ui-kitten/components'
import DatePicker from '../../ui/primitive/components/DatePicker'
import { OrdersContext } from './OrdersTableContext'

const Header: FC = () => {
  // const styles = useStyleSheet(themedStyles)
  const { dateRange, setDateRange } = useContext(OrdersContext)
  const desktopStyles = useStyleSheet(themedStyles)
  const mobileStyles = useStyleSheet(themedSmStyles)
  const { width } = useWindowDimensions()
  const isSmallDevice = width < 900
  const styles = isSmallDevice
    ? { ...desktopStyles, ...mobileStyles }
    : desktopStyles

  const setRange = (range) => {
    setDateRange(range)
  }

  const clearRange = () => {
    setDateRange({ endDate: new Date(), startDate: new Date() })
  }

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Table Order</Text>
        <Text style={styles.subTitle}>See all order details</Text>
      </View>
      <View style={styles.picker}>
        <DatePicker
          visibleDate={dateRange}
          onCancel={clearRange}
          onPush={setRange}
        ></DatePicker>
      </View>
    </View>
  )
}

export default Header

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 87,
    paddingLeft: 8,
    marginBottom: 32,
  },
  title: {
    fontSize: 22,
    fontWeight: '700',
  },
  subTitle: {
    fontSize: 15,
  },
  button: {
    backgroundColor: 'lightGrey',
    borderWight: 1,
    borderColor: 'grey',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 18,
  },
  text: {
    marginLeft: 12,
    color: 'textColorSecondary',
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  picker: {
    alignItems: 'flex-end',
    paddingHorizontal: 20,
    paddingVertical: 3,
  },
})

const themedSmStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 0,
    paddingLeft: 8,
    marginBottom: 16,
  },
  button: {
    backgroundColor: '#f2f3f5',
    borderWight: 1,
    borderColor: 'grey',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 12,
  },
  text: {
    marginLeft: 8,
    fontSize: 12,
    color: 'textColorSecondary',
    textTransform: 'uppercase',
    fontWeight: '700',
  },
})
