import React, { FC } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'
import { OrderTransform } from '../../../utils/orderTransform'
import {
  snackbarStateVar,
  SnackbarStatus,
} from '../../primitive/components/Snackbar'
import Order from '../../../features/ordersScreen/Order'
import { modalStateVar } from '../../primitive/components/Modal'
import OrderModalContainer from '../../../features/ordersScreen/OrderModalContainer'

const TableBody: FC<ITableBody> = React.memo(({ list, endPoll, startPoll }) => {
  const styles = useStyleSheet(themedStyles)

  const openOrderModal = async (order: { id: number }) => {
    try {
      if (endPoll) {
        endPoll()
      }
      await new Promise<void>((resolve) => {
        modalStateVar({
          open: true,
          onSubmit: () => {
            resolve()
          },
          children: ({ closeHandler }) => (
            <OrderModalContainer
              order={order}
              closeHandler={() => {
                closeHandler()
                if (startPoll) {
                  startPoll(1000)
                }
              }}
            />
          ),
        })
      })
    } catch (e) {
      if (startPoll) {
        startPoll(1000)
      }
      snackbarStateVar({
        open: true,
        status: SnackbarStatus.ERROR,
        message: e.message,
      })
    }
  }

  return (
    <View style={styles.container}>
      {list.map((order) => (
        <Order
          key={order.id}
          data={order}
          openOrderModal={() => {
            openOrderModal(order)
          }}
        />
      ))}
    </View>
  )
})

export default TableBody

interface ITableBody {
  list: OrderTransform[]
  startPoll?: (value: number) => void
  endPoll?: () => void
}

const themedStyles = StyleService.create({
  container: {},
  last: {
    paddingRight: 24,
  },
  first: {
    paddingLeft: 24,
  },
  item: {
    flexDirection: 'row',
    paddingVertical: 28,
    borderBottomColor: 'borderColorPrimary',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  new: {
    backgroundColor: 'greyBackground',
  },
  tableId: {
    minWidth: 144,
    fontSize: 16,
    fontWeight: '700',
  },
  tableColum: {
    width: '16%',
    minWidth: 120,
    justifyContent: 'center',
  },
  text: {
    fontSize: 13,
    color: 'textColorTableBody',
  },
  priceContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
  },
  paidContainer: {
    backgroundColor: '#F0FFF5',
  },
  unPaidContainer: {
    backgroundColor: '#FFF2F2',
  },
  price: {
    fontSize: 13,
    fontWeight: '700',
  },
  priceUnPaid: {
    color: 'pink',
  },
  pricePaid: {
    color: 'green',
  },
  icon: {
    width: 24,
    height: 24,
  },
})
