import { gql, useMutation } from '@apollo/client'
import { RefundOrder, RefundOrderVariables } from './types/RefundOrder'

export const REFUND_FRAGMENT = gql`
  fragment RefundFragment on Refund {
    id
    paymentId
    state
    transactionId
    updatedAt
    adjustment
    items
    method
    metadata
  }
`

const REFUND_ORDER = gql`
  mutation RefundOrder($input: RefundOrderInput!) {
    refundOrder(input: $input) {
      ... on ErrorResult {
        errorCode
        message
      }
      ...RefundFragment
    }
  }
  ${REFUND_FRAGMENT}
`

export const useRefundOrder = () =>
  useMutation<RefundOrder, RefundOrderVariables>(REFUND_ORDER)
