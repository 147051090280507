import subDays from 'date-fns/subDays'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addDays from 'date-fns/addDays'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'

export const predefinedRanges = [
  {
    label: 'Today',
    value: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  {
    label: 'Yesterday',
    value: {
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
    },
  },
  {
    label: 'Last 7 days',
    value: {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
    },
  },
  {
    label: 'Current Week',
    value: {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
    },
  },
  {
    label: 'This month',
    value: {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
    },
  },
  {
    label: 'Last month',
    value: {
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    },
  },
]
