import React, { FC } from 'react'
import { Text, View } from 'react-native'
import { StyleService, useStyleSheet } from '@ui-kitten/components'

const OrderStatus: FC<IOrderStatus> = ({ state, number, seen }) => {
  const styles = useStyleSheet(themedStyles)
  const colorStyle = number % 2 === 0 ? styles.blue : styles.pink

  return (
    <Text style={styles.statusContainer}>
      <View style={[styles.status, colorStyle]}>
        <Text>{state}</Text>
      </View>
      {!seen && (
        <View style={[styles.status, styles.pink, styles.lastChild]}>
          <Text>unseen</Text>
        </View>
      )}
    </Text>
  )
}

export default OrderStatus

const themedStyles = StyleService.create({
  status: {
    color: 'textColorWhite',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 25,
  },
  statusContainer: {
    marginRight: 36,
  },
  blue: {
    backgroundColor: 'blue',
  },
  pink: {
    backgroundColor: 'pink',
  },
  lastChild: {
    marginLeft: 9,
  },
})

export enum Status {
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  PAYMENT_SETTLED = 'PaymentSettled',
  ARRANGING_PAYMENT = 'ArrangingPayment',
  ARRANGING_ADDITIONAL_PAYMENT = 'ArrangingAdditionalPayment',
  PAY_LATER = 'PayLater',
  ADDING_ITEMS = 'AddingItems',
  MODIFYING = 'Modifying',
  CUSTOM_MODIFYING = 'CustomModifying',
  CANCELLED = 'Cancelled',
  ORDER_PLACED = 'OrderPlaced',
  FULFILLMENT = 'Fulfillment',
  DELIVERED = 'Delivered',
  REFUNDED = 'Refunded',
  WAITING_WAITERS = 'WaitingWaiters',
}

interface IOrderStatus {
  state: Status
  number: number
  seen: boolean
}
