import { gql, useMutation } from '@apollo/client'
import { Login, LoginVariables } from './types/Login'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(username: $email, password: $password) {
      ... on CurrentUser {
        id
        identifier
        channels {
          code
        }
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`

export const useLogin = () => useMutation<Login, LoginVariables>(LOGIN)
